import React, { memo } from 'react';
import { Button, Flex, Input } from 'antd';
import { useParams } from 'react-router-dom';
import {
    PlusOutlined,
    AppstoreOutlined,
    BarsOutlined,
} from '@ant-design/icons';

import { TreeCategoryFilter } from '../Categories/TreeCategoryFilter';
import { useManageProducts } from '../../hooks/useManageProducts';
import { useUpdateProductsType } from '../../hooks/useUpdateProductsType';
import {
    ProductsFilters,
    ProductsViewType,
} from '../../interfaces/products.interface';

type ProductTabs = {
    readonly updateFilters: (filters: ProductsFilters) => void;
    readonly setViewType: (viewType: ProductsViewType) => void;
    readonly viewType: ProductsViewType;
    readonly filters: ProductsFilters;
};
export const ProductTabs = memo((props: ProductTabs) => {
    const { updateFilters, setViewType, viewType, filters } = props;

    const { id, type } = useParams();
    const { manageProduct } = useManageProducts();
    const { updateAllProductsType } = useUpdateProductsType();

    const renderCreateButton = (type: string) => {
        switch (type) {
            case 'product-order':
                return 'Добавить заявку';
            case 'links':
                return 'Добавить витрину';
            default:
                return 'Добавить товар';
        }
    };

    const renderViewTypeButton = (viewType: ProductsViewType) => {
        switch (viewType) {
            case ProductsViewType.cards:
                return (
                    <Button
                        style={{ padding: '0px 0.5rem' }}
                        onClick={() => setViewType(ProductsViewType.table)}
                    >
                        <BarsOutlined />
                    </Button>
                );
            case ProductsViewType.table:
                return (
                    <Button
                        style={{ padding: '0px 0.5rem' }}
                        onClick={() => setViewType(ProductsViewType.cards)}
                    >
                        <AppstoreOutlined />
                    </Button>
                );
        }
    };

    return (
        <Flex justify={'space-between'} wrap={'wrap'} gap={20}>
            <Flex vertical={false} gap={12}>
                <Button
                    type={'primary'}
                    onClick={() => {
                        manageProduct(type, +id);
                    }}
                >
                    <PlusOutlined /> {renderCreateButton(type)}
                </Button>

                <div>
                    <Input
                        value={filters.title}
                        allowClear
                        onChange={e =>
                            updateFilters({ ...filters, title: e.target.value })
                        }
                        placeholder={'Поиск товаров'}
                    />
                </div>
            </Flex>

            <Flex vertical={false} gap={12}>
                <TreeCategoryFilter
                    filters={filters}
                    updateFilters={updateFilters}
                />
                {type === 'links' && (
                    <Button
                        onClick={() => {
                            updateAllProductsType('product', +id);
                        }}
                    >
                        Перевести витрины в товары
                    </Button>
                )}

                {type === 'products' && renderViewTypeButton(viewType)}
            </Flex>
        </Flex>
    );
});
