import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';

import { ProductForm } from '../../views/Products/ProductForm';
import { useCreateProductMutation } from '../../services/products';
import { createFormData } from '../../../../utils/common/createFormData';
import {
    IProducts,
    ProductStatus,
    ProductsTypes,
} from '../../interfaces/products.interface';

export const ProductCreateContainer = () => {
    const { id, type } = useParams();
    const { notification } = App.useApp();
    const navigate = useNavigate();
    const isLinkPage = type === 'links';

    const [
        createProduct,
        {
            isSuccess: isSuccessCreate,
            isLoading: isLoadingCreate,
            isError: isErrorCreate,
        },
    ] = useCreateProductMutation();

    useEffect(() => {
        if (isSuccessCreate) {
            notification.success({
                message: isLinkPage
                    ? `Витрина успешно создана`
                    : `Товар успешно создан`,
            });
            navigate(-1);
        }
    }, [isSuccessCreate]);

    useEffect(() => {
        if (isErrorCreate) {
            notification.error({
                message: 'Произошла ошибка',
            });
        }
    }, [isErrorCreate]);

    const onSave = async (values: IProducts) => {
        if (values.title === '') {
            return notification.error({
                message: 'Нужно указать  название товара',
            });
        }

        if (isLinkPage && values.link === '') {
            return notification.error({
                message: 'Нужно указать ссылку на товар',
            });
        }

        if (values.images?.length < 1) {
            return notification.error({
                message: 'Нужно загрузить картинку',
            });
        }

        if (!isLinkPage && values.price === 0) {
            return notification.error({
                message: `Цена не может быть ${values.price}`,
            });
        }

        if (values.old_price && values.price > +values.old_price) {
            return notification.error({
                message: `Цена без скидки не может быть меньше ${values.price}`,
            });
        }

        const data = {
            shop_id: +id,
            title: values.title,
            description: values.description,
            price: values.price,
            old_price: values.old_price,
            images: values.images,
            buyable: values.buyable,
            category_ids: values.category_ids,
            status: values.status,
            link: values.link,
            type: values.type,
        };

        if (!values.old_price) {
            delete data.old_price;
        }

        if (!isLinkPage) {
            delete data.link;
        }

        if (isLinkPage && !values.price) {
            data.price = 0;
        }

        await createProduct(createFormData(data));
    };

    const initialValues = (type: string) => {
        const setTitle = type => {
            switch (type) {
                case 'products':
                    return 'Название товара';
                case 'links':
                    return 'Название витрины';
                case 'product-order':
                    return 'Название заявки';
                default:
                    return 'Название товара';
            }
        };

        const setType = type => {
            switch (type) {
                case 'products':
                    return ProductsTypes.product;
                case 'links':
                    return ProductsTypes.link;
                case 'product-order':
                    return ProductsTypes.productOrder;
                default:
                    return ProductsTypes.product;
            }
        };

        return {
            title: setTitle(type),
            description: '',
            price: null,
            old_price: null,
            images: [],
            buyable: false,
            category_ids: [0],
            status: ProductStatus.active,
            type: setType(type),
        };
    };

    return (
        <ProductForm
            shopId={+id}
            initialValues={initialValues(type)}
            onSave={onSave}
            loading={isLoadingCreate}
        />
    );
};
