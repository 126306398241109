export const defaultPalette = {
    // menuColor: {
    //     hex: 'linear-gradient(272deg, #FFCAF6 0.71%, #E6DAFF 98.98%)',
    //     hexPrimary: '#FFCAF6',
    //     hexSecondary: '#E6DAFF',
    // },
    // borderGradient: {
    //     hex: 'linear-gradient(152deg, #BF5DBB -0.35%, #6357D2 108.49%)',
    //     hexPrimary: '#BF5DBB',
    //     hexSecondary: '#6357D2',
    // },
    // primaryGradient: {
    //     hex: 'linear-gradient(338deg, #956BF9 4.27%, #D25EB9 90.65%)',
    //     hexPrimary: '#956BF9',
    //     hexSecondary: '#D25EB9',
    // },
    colorPrimary: {
        hex: '#2357FF',
    },
    colorSecondary: {
        hex: '#FAA6D8',
    },
    colorCaption: {
        hex: '#1F2937',
    },
    colorStatus: {
        hex: '#E1DCF7',
    },
    colorButtonSecondary: {
        hex: '#FDA2F6',
    },
    colorText: {
        hex: '#1F2937',
    },
    colorTextSecondary: {
        hex: '#4A5568',
    },
    // borderColor: {
    //     hex: '#25212940',
    // },
    borderColorSecondary: {
        hex: '#25212926',
    },
    backgroundSecondary: {
        hex: '#F8F8F8',
    },
    backgroundLayout: {
        hex: '#FFFFFF',
    },
    colorSuccess: {
        hex: '#55F8B4',
    },
    colorBackgroundSuccess: {
        hex: '#C0FAD0',
    },
    colorBackgroundSuccessSecondary: {
        hex: '#F0FFF4',
    },
    colorError: {
        hex: '#E24041',
    },
    colorBackgroundError: {
        hex: '#FFEDEB',
    },
    colorBackgroundSecondary: {
        hex: '#FFC8C2',
    },
};
