import React, { FC, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Flex } from 'antd';

import StaticText from 'shared/layouts/LandingLayout/content.json';
import Museum from 'static/landing/Museum.png';
import Opera from 'static/landing/Opera.png';
import Pushkin from 'static/landing/Pushkin.png';

import { SubTitle, Title } from '../ui/Title';
import { Container } from '../ui/Container';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenProps } from '../types/landing.interface';
import { Arrow } from '../../../ui/Icons/Arrow';

export const AboutUs: FC<ScreenProps> = ({ refProp }) => {
    const { size } = useWindowSize();
    const { hash } = useLocation();

    useLayoutEffect(() => {
        if (refProp && hash === '#create-store') {
            setTimeout(() => {
                refProp.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                });
            }, 500);
        }
    }, [refProp]);

    const renderImage = name => {
        switch (name) {
            case 'Museum':
                return <StyledImage src={Museum} $background={Museum} />;

            case 'Opera':
                return <StyledImage src={Opera} $background={Opera} />;

            case 'Pushkin':
                return <StyledImage src={Pushkin} $background={Pushkin} />;

            default:
                return <></>;
        }
    };

    return (
        <Container
            maxWidth={1000}
            marginTop={102}
            mobileMarginTop={40}
            refProp={refProp}
        >
            <Flex vertical justify={'center'} gap={0}>
                <SubTitle
                    id={'telegram-advantages'}
                    type='primary'
                    text={StaticText.aboutUs.title}
                />
                <StyledDescription>
                    {StaticText.aboutUs.description}
                </StyledDescription>
                <StyledOptionsList gap={43}>
                    {StaticText.aboutUs.options.map((option, index) => (
                        <StyledOption key={`${option.title}${index}`}>
                            <StyledOptionSubCard $isDark={option.isDark}>
                                <StyledOptionSubCardTitle>
                                    {option.subTitle}
                                </StyledOptionSubCardTitle>
                                <StyledOptionSubCardDesc>
                                    {option.subDescribtion}
                                </StyledOptionSubCardDesc>
                            </StyledOptionSubCard>

                            <StyledOptionCard>
                                <Flex vertical>
                                    {renderImage(option.image)}
                                    <StyledOptionTitle>
                                        {option.title}
                                    </StyledOptionTitle>
                                    <StyledOptionDescription>
                                        {option.description}
                                    </StyledOptionDescription>
                                </Flex>
                                <a
                                    href={option.link}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <StyledArrow />
                                </a>
                            </StyledOptionCard>
                        </StyledOption>
                    ))}
                </StyledOptionsList>
            </Flex>
        </Container>
    );
};

const StyledOptionsList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;

    @media (max-width: 1150px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`;

const StyledOption = styled.div`
    position: relative;
    padding-left: 50px;
    flex: 1 1 33%;
`;

const StyledDescription = styled.div`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 27.2px;
    letter-spacing: 0%;
    margin-bottom: 24px;

    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 136%; /* 19.04px */
        margin: 6px 0 39px;
    }
`;

const StyledOptionSubCard = styled.div<{ $isDark: boolean }>`
    background: ${props => (props.$isDark ? '#2357FF' : '#CEAAF6')};
    color: ${props => (props.$isDark ? '#FFFFFF' : '#1F2937')};
    position: absolute;
    left: 0;
    top: 58px;
    border-radius: 20px;
    width: 122px;
    height: 116px;
    padding: 12px 14px 4px;
    z-index: 2;

    @media (max-width: 920px) {
        left: -13px;
        top: 40px;
    }
`;

const StyledOptionCard = styled.div`
    background: #f3f4f6;
    border-radius: 30px;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0;
    width: 288px;
    height: 448px;
    clip-path: path(
        'M265.888 44.6549C253.676 44.6549 243.777 34.6478 243.777 22.4359C243.777 10.1048 233.78 0 221.449 0H30C13.4314 0 0 13.4315 0 30V418C0 434.569 13.4315 448 30 448H258C274.569 448 288 434.569 288 418L288 66.7666C288 54.5547 278.1 44.6549 265.888 44.6549Z'
    );

    @media (max-width: 920px) {
        height: 342px;
        padding: 24px 20px 14px;
        clip-path: path(
            'M265.888 44.6128C253.676 44.6128 243.777 34.6162 243.777 22.4042C243.777 10.0847 233.79 0 221.47 0H30C13.4315 0 3.05176e-05 13.4315 3.05176e-05 30L0 312C0 328.569 13.4315 342 30 342H258C274.569 342 288 328.569 288 312L288 66.7245C288 54.5126 278.1 44.6128 265.888 44.6128Z'
        );
    }
`;

const StyledImage = styled.div<{ $background: string }>`
    background-image: ${props => `url(${props.$background})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 248px;
    height: 162px;
    clip-path: path(
        'M0 18C0 8.05887 8.05888 0 18 0H192.059C199.791 0 206.059 6.26802 206.059 14V18.3684C206.059 29.4141 215.013 38.3684 226.059 38.3684H234C241.732 38.3684 248 44.6364 248 52.3684V144C248 153.941 239.941 162 230 162H18C8.05887 162 0 153.941 0 144V18Z'
    );

    margin-bottom: 21px;
    @media (max-width: 920px) {
        margin-bottom: 21px;
        height: 145px;
        border-radius: 16px;
    }
`;

const StyledOptionTitle = styled.h2`
    color: #1f2937;

    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin: 0 0 18px;

    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 14px;
    }
`;

const StyledOptionDescription = styled.div`
    color: #1f2937;

    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%; /* 24.48px */
    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 19px;
    }
`;

const StyledOptionSubCardTitle = styled.div`
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%; /* 21.76px */

    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 19px;
    }
`;

const StyledOptionSubCardDesc = styled.div`
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%; /* 16.32px */
`;

const StyledArrow = styled(Arrow)`
    &:hover path {
        stroke: #6257d2 !important;
    }
`;
