import React, { FC, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Flex, Row } from 'antd';

import CreateSpodialStoreImage from 'static/landing/create-spodial-store.png';
import StaticText from 'shared/layouts/LandingLayout/content.json';

import { SubTitle, Title } from '../ui/Title';
import { Container } from '../ui/Container';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenProps } from '../types/landing.interface';
import { CheckIcon } from '../../../ui/Icons/CheckIcon';
import { CrossIcon } from '../../../ui/Icons/CrossIcon';
import { defaultPalette } from '../utils/landingThemePalette';
import { DemoEmail } from '../components/DemoEmail';

export const PathToPurchase: FC<ScreenProps> = () => {
    return (
        <Container maxWidth={1000} marginTop={98} mobileMarginTop={42}>
            <Flex vertical justify={'center'}>
                <SubTitle
                    id={'telegram-advantages'}
                    type='primary'
                    text={StaticText.pathToPurchase.title}
                />
                <StyledCard>
                    <StyledTable>
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <StyledHeadLabelApp>
                                        {
                                            StaticText.pathToPurchase
                                                .tableTitles.application
                                        }
                                    </StyledHeadLabelApp>
                                </th>
                                <th>
                                    <StyledHeadSite>
                                        {
                                            StaticText.pathToPurchase
                                                .tableTitles.site
                                        }
                                    </StyledHeadSite>
                                </th>
                                <th>
                                    <StyledHeadMiniApp>
                                        {
                                            StaticText.pathToPurchase
                                                .tableTitles.miniApp
                                        }
                                    </StyledHeadMiniApp>
                                </th>
                            </tr>
                        </thead>
                        <tr>
                            <td width={406} align={'left'}>
                                <StyledLabel>
                                    {StaticText.pathToPurchase.options[0]}
                                </StyledLabel>
                            </td>
                            <td width={194}>
                                <CrossIcon
                                    color={defaultPalette.colorSecondary.hex}
                                />
                            </td>
                            <td width={145}>
                                <CheckIcon
                                    color={defaultPalette.colorSuccess.hex}
                                />
                            </td>
                            <td width={204}>
                                <CheckIcon
                                    color={defaultPalette.colorSuccess.hex}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td align={'left'}>
                                <StyledLabel>
                                    {StaticText.pathToPurchase.options[1]}
                                </StyledLabel>
                            </td>
                            <td>
                                <CrossIcon
                                    color={defaultPalette.colorSecondary.hex}
                                />
                            </td>
                            <td>
                                <CrossIcon
                                    color={defaultPalette.colorSecondary.hex}
                                />
                            </td>
                            <td>
                                <CheckIcon
                                    color={defaultPalette.colorSuccess.hex}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td align={'left'} style={{ paddingBottom: '0' }}>
                                <StyledLabel>
                                    {StaticText.pathToPurchase.options[2]}
                                </StyledLabel>
                            </td>
                            <td style={{ paddingTop: '16px' }}>
                                <CrossIcon
                                    color={defaultPalette.colorSecondary.hex}
                                />
                            </td>
                            <td style={{ paddingTop: '16px' }}>
                                <CrossIcon
                                    color={defaultPalette.colorSecondary.hex}
                                />
                            </td>
                            <td
                                style={{ paddingTop: '16px', color: '#2357FF' }}
                            >
                                <CheckIcon
                                    color={defaultPalette.colorSuccess.hex}
                                />
                            </td>
                        </tr>
                    </StyledTable>
                    <DemoEmail
                        activator={
                            <StyledButton type='primary'>
                                {StaticText.pathToPurchase.actions.buttonText1}
                            </StyledButton>
                        }
                    />
                </StyledCard>
            </Flex>
        </Container>
    );
};

const StyledCard = styled.div`
    background: white;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    padding: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    margin-top: 36px;
    z-index: 2;

    @media (max-width: 920px) {
        padding: 20px 16px;
        margin-top: 30px;
    }
`;

const StyledTable = styled.table`
    border: none;
    text-align: center;
    border-collapse: collapse;

    thead th {
        border-bottom: 1px solid #e8e8e8;
        color: #4a5568;
        padding-bottom: 7px;
        line-height: 30px;

        @media (max-width: 920px) {
            //padding: 10px 2px 10px 0;
            line-height: 18px;
        }
    }

    thead th:not(:last-child) {
        border-right: 1px solid #e8e8e8;
    }

    tr td:not(:last-child) {
        border-right: 1px solid #e8e8e8;
    }

    tr td:first-child {
        padding: 14px 64px 15px 0;
        color: #4a5568;
        line-height: 18px;

        @media (max-width: 920px) {
            padding: 11px 2px 14px 0;
            line-height: 18px;
        }
    }

    tr:not(:last-child) {
        border-bottom: 1px solid #e8e8e8;
    }
`;

const StyledLabel = styled.label`
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;

    @media (max-width: 920px) {
        font-size: 12px;
        line-height: 18px;
    }
`;

const StyledHeadLabelApp = styled.label`
    text-align: center;
    font-size: 20px;
    line-height: 18px;

    @media (max-width: 500px) {
        padding: 0 3px 8px 3px;
    }

    @media (max-width: 920px) {
        padding: 0;
        font-size: 12px;
        line-height: 18px;
    }
`;

const StyledHeadSite = styled.label`
    text-align: center;
    font-size: 20px;
    line-height: 18px;
    padding: 0 8px;

    @media (max-width: 500px) {
        padding: 0 8px;
    }

    @media (max-width: 920px) {
        padding: 0 8px;
        font-size: 12px;
        line-height: 18px;
    }
`;

const StyledHeadMiniApp = styled.label`
    color: #2357ff;
    text-align: center;
    font-size: 20px;
    line-height: 18px;
    font-weight: 700;
    padding: 0 11px;

    @media (max-width: 500px) {
        padding: 0 1px;
    }

    @media (max-width: 920px) {
        padding: 0 2px;
        font-size: 12px;
        line-height: 18px;
    }
`;

const StyledButton = styled(Button)`
    padding: 0px 24px;
    background: rgb(35, 87, 255);
    color: rgb(255, 255, 255);
    border: none;
    height: 68px;
    border-radius: 100px;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
    margin: 30px 0 0px;

    &:hover {
        background: #8ea6f8 !important;
    }

    &:active {
        background: #6588fa !important;
    }

    @media (max-width: 920px) {
        font-size: 12px;
        line-height: 16px;
        padding: 0px 26px;
        height: 32px;
        margin: 24px auto 5px;
    }
`;
