import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Checkbox,
    Flex,
    Image,
    Skeleton,
    Typography,
} from 'antd';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';

import Logo from '../../../../static/images/logo.png';
import { Benefits } from '../Benefits';
import { rub } from '../../../../shared/contants/styles';
import { useTheme } from '../../../../hooks/useTheme';
import { usePaySubscriptionMutation } from '../../services';
import { useProfileData } from '../../../../hooks/useProfileData';
import { SubscriptionProps } from '../../interfaces';
import { SubscriptionSlug } from '../../interfaces/subscription.interface';
import getEnvConfig from '../../../../utils/config/env';

const { Text, Title } = Typography;
export const Subscription = () => {
    const token = useTheme();

    const {
        user,
        subscription: userSubscription,
        error,
        subscriptions,
        isLoadingSubscription,
        isLoadingUser,
    } = useProfileData();
    const [paySubscription, { isLoading: isLoadingPay }] =
        usePaySubscriptionMutation();

    const [checkedSubscription, setCheckedSubscription] =
        useState<boolean>(false);
    const [checkedLegal, setCheckedLegal] = useState<boolean>(false);

    const navigate = useNavigate();

    const [subscription, setSubscription] = useState<SubscriptionProps>(null);
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
        if (!isLoadingSubscription && subscriptions) {
            const paidSubscription = subscriptions.find(
                elem => elem.slug === SubscriptionSlug.payJuriPlan
            );
            setSubscription(paidSubscription);
        }
    }, [isLoadingSubscription, subscriptions]);

    useEffect(() => {
        if (user?.subscription?.subscription_id === 1) {
            setIsExpired(false);
        }
    }, [user]);

    useEffect(() => {
        if (userSubscription?.slug === SubscriptionSlug.payJuriPlan) {
            return navigate('/app/shops', { replace: true });
        }
    }, [userSubscription]);

    useEffect(() => {
        if (error) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setIsExpired(error.status === 403);
        }
    }, [error]);

    const navigateAuthPage = () => {
        try {
            if (localStorage.getItem('accessToken')) {
                localStorage.removeItem('accessToken');
            }
        } catch (e) {
            /* empty */
        }
        return navigate(`${getEnvConfig().PUBLIC_PATH}/auth/sign-in`);
    };

    const getSuccessUrl = () => {
        return window.ENV.APP_URL + '/pay/pending';
    };

    const handlePaySubscription = async (type: number) => {
        const payload = {
            subscription_id: subscription.id,
            payment_method: type,
            success_url: getSuccessUrl(),
        };

        const action = async () => {
            const response = await paySubscription(payload).unwrap();
            window.location.href = response.redirect;
        };

        switch (type) {
            case 1:
                return navigate('/pay/legal');
            case 2:
                return action();
        }
    };

    const disabled = !checkedSubscription || !checkedLegal;

    return (
        <>
            {isLoadingUser ? null : (
                <Wrapper gap={24} align={'center'} justify={'center'}>
                    <StyledCard
                        type='inner'
                        style={{ ...token.Card, width: 380 }}
                    >
                        <Flex vertical gap={24}>
                            <Image
                                preview={false}
                                style={{ width: '50%' }}
                                src={Logo}
                                alt='Logo'
                            />

                            <Flex vertical gap={8}>
                                <Title style={{ margin: 0 }} level={3}>
                                    {isExpired ? 'Продлите' : 'Оформите'}{' '}
                                    подписку
                                </Title>
                            </Flex>

                            <Flex vertical gap={8}>
                                <Flex vertical={false} gap={4}>
                                    <Text>Тариф</Text>
                                    <Text strong>«{subscription?.name}»:</Text>
                                </Flex>

                                {isLoadingSubscription && !subscriptions ? (
                                    <Skeleton />
                                ) : (
                                    <Benefits
                                        source={subscription?.description}
                                    />
                                )}
                            </Flex>

                            <Flex vertical gap={4}>
                                <Flex gap={4} align={'center'}>
                                    <div
                                        style={{
                                            height: '30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Title
                                            delete
                                            type={'secondary'}
                                            style={{ margin: 0 }}
                                            level={5}
                                        >
                                            {subscription?.price_old}
                                            {rub}
                                        </Title>
                                    </div>

                                    <div style={{ height: '30px' }}>
                                        <Title style={{ margin: 0 }} level={4}>
                                            {subscription?.price}
                                            {rub} в месяц
                                        </Title>
                                    </div>
                                </Flex>

                                <Text strong>
                                    Подпишись сейчас и закрепи за собой цену
                                </Text>

                                <Text type={'secondary'}>
                                    Вернем деньги в течении 10 дней, если вам не
                                    понравится
                                </Text>
                            </Flex>

                            <Flex gap={8} vertical={false} align={'flex-start'}>
                                <Checkbox
                                    onChange={({ target }) =>
                                        setCheckedSubscription(target.checked)
                                    }
                                />
                                <Flex gap={4} vertical>
                                    <Text>
                                        Нажимая кнопку, я соглашаюсь на
                                        подключение подписки Spodial*
                                    </Text>
                                </Flex>
                            </Flex>

                            <Text
                                style={{ marginTop: '-16px' }}
                                type={'secondary'}
                            >
                                *Подписка подключается на месяц за{' '}
                                {subscription?.price} рублей с последующим
                                автопродлением. 1 месяц равен 30 календарным
                                дням. Оплата пройдёт за 24 часа до продления.
                                Автопродление можно отключить в любой момент в
                                личном кабинете (Подписка - “отключить”), но не
                                позднее, чем за 24 часа до продления.
                            </Text>

                            <Flex gap={8} vertical={false} align={'flex-start'}>
                                <Checkbox
                                    onChange={({ target }) =>
                                        setCheckedLegal(target.checked)
                                    }
                                />

                                <Flex vertical>
                                    <Text>
                                        Нажимая кнопку, я соглашаюсь с{' '}
                                        <Link to={`/user_agreement`}>
                                            Пользовательским соглашением
                                        </Link>
                                        ,
                                        <Link to={`/privacy_policy`}>
                                            {' '}
                                            Политикой обработки персональных
                                            данных
                                        </Link>
                                        ,
                                        <Link to={'/payment_rules'}>
                                            {' '}
                                            Правилами совершения платежей и
                                            возврата оплаты
                                        </Link>{' '}
                                        и даю
                                        <Link to={'/personal-data-agreement'}>
                                            {' '}
                                            Согласие на обработку моих
                                            персональных данных.
                                        </Link>
                                    </Text>
                                </Flex>
                            </Flex>

                            <Flex vertical gap={16}>
                                <Button
                                    loading={
                                        isLoadingSubscription || isLoadingPay
                                    }
                                    disabled={!!disabled}
                                    type={'primary'}
                                    onClick={() => handlePaySubscription(2)}
                                >
                                    Оплатить картой
                                </Button>
                                <Button
                                    loading={
                                        isLoadingSubscription || isLoadingPay
                                    }
                                    disabled={!!disabled}
                                    onClick={() => handlePaySubscription(1)}
                                >
                                    Выставить счет
                                </Button>
                            </Flex>

                            <Flex
                                style={{ marginBottom: '16px' }}
                                justify={'center'}
                            >
                                <Typography.Link
                                    onClick={() => navigateAuthPage()}
                                    style={{ color: '#6257d2' }}
                                >
                                    Вернуться на экран авторизации
                                </Typography.Link>
                            </Flex>
                        </Flex>
                    </StyledCard>
                </Wrapper>
            )}
        </>
    );
};

const StyledCard = styled(Card)`
    padding-top: 16px;

    @media (max-width: 575px) {
        box-shadow: none !important;
        border: none;
    }
`;

const Wrapper = styled(Flex)`
    height: 100%;
    width: 100%;
`;
