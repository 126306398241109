import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useLazyReadProductsQuery } from '../services/products';
import { ProductsFilters } from '../interfaces/products.interface';
import { selectInitialFilters } from '../utils/filters';
import { isProductsActionsVisible } from '../utils/isProductsActionsVisible';

export const useList = () => {
    const { id, type } = useParams();

    const [filters, setFilters] = useState({
        ...selectInitialFilters(type, +id),
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isFiltersEqual, setIsFiltersEqual] = useState(true);

    const [
        getProducts,
        { data: list = { items: [], total: 0 }, isLoading, isFetching },
    ] = useLazyReadProductsQuery();

    useEffect(() => {
        if (filters) {
            setIsFiltersEqual(
                JSON.stringify(filters) ===
                    JSON.stringify(selectInitialFilters(type, +id))
            );
        }
    }, [filters]);

    useEffect(() => {
        setFilters({
            ...selectInitialFilters(type, +id),
        });
    }, [type]);

    useEffect(() => {
        if (filters) {
            getProducts(filters, true);
        }
    }, [filters]);

    const rowSelection = {
        selectedRowKeys: selectedRowKeys.map(item => item.id),
        onChange: (_, record) => setSelectedRowKeys(record),
        getCheckboxProps: record => ({
            id: record.id,
        }),
    };

    const updateFilters = (update: ProductsFilters) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters(null);
        }
    };

    const pagination = (total: number) => {
        const pageSizeOptions = (total: number) => {
            const calculateTotal = (size: number) => {
                return Math.floor(total / size);
            };

            return [
                calculateTotal(100),
                calculateTotal(10),
                calculateTotal(4),
                calculateTotal(2),
                total,
            ].filter(size => size > 0);
        };

        return {
            defaultCurrent: filters.page,
            current: filters.page,
            showTitle: true,
            defaultPageSize: filters.limit,
            pageSizeOptions: pageSizeOptions(total),
            onChange: (page: number, pageSize: number) => {
                updateFilters({
                    offset: (page - 1) * pageSize,
                    limit: pageSize,
                    page: page,
                });
            },
            total: list.total,
            showTotal: (total: number) => `Товаров: ${total}`,
        };
    };

    const loading = isFetching || isLoading;

    const isMultipleActionsAvailable = isProductsActionsVisible(
        selectedRowKeys?.length
    );

    return {
        list,
        updateFilters,
        pagination,
        loading,
        filters,
        rowSelection,
        isMultipleActionsAvailable,
        isFiltersEqual,
        setSelectedRowKeys,
    };
};
