import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Card, Typography, Skeleton, Button, App } from 'antd';
import { useParams } from 'react-router-dom';

import { getSrc } from '../../../../../../../utils/common/getSrc';
import { useTheme } from '../../../../../../../hooks/useTheme';
import { useReadAuthorPublicByIdQuery } from '../../../../../services/author';
import {
    useCreateWebinarUserMutation,
    useLazyReadWebinarHistoryQuery,
} from '../../../../../services/webinars';

export const AuthorPublicWebinar = props => {
    const token = useTheme();
    const { id } = useParams();
    const { notification } = App.useApp();

    const {
        data: author,
        isSuccess,
        isError,
        isFetching,
    } = useReadAuthorPublicByIdQuery(props.authorId);

    const [createWebinarUser, { isSuccess: isSuccessCreate, data: response }] =
        useCreateWebinarUserMutation();

    const [
        getWebinarHistory,
        { data: webinar, error, isError: isErrorWebinar, isLoading },
    ] = useLazyReadWebinarHistoryQuery();

    useEffect(() => {
        if (isErrorWebinar && error) {
            notification.error({
                message: error?.data?.message || 'Произошла ошибка',
            });
        }
    }, [isErrorWebinar, error]);

    useEffect(() => {
        if (isSuccessCreate) {
            window.open(response.redirectUrl);
        }
    }, [isSuccessCreate]);

    return (
        <StyledContent>
            <Card hoverable type='inner' style={token.Card}>
                {isFetching || isError ? (
                    <Skeleton />
                ) : (
                    <StyledWrapper>
                        <StyledDescription>
                            <StyledTitle level={2}>{author?.name}</StyledTitle>
                            <Typography.Text>{author?.about}</Typography.Text>
                            <Button
                                loading={isLoading}
                                // onClick={() => window.open(props.url)}
                                onClick={() => getWebinarHistory(+id)}
                                style={{ marginTop: '1rem', maxWidth: '200px' }}
                                type={'primary'}
                            >
                                Смотреть вебинар
                            </Button>
                            {webinar?.embed && (
                                <div
                                    style={{
                                        marginTop: 16,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: webinar.embed,
                                    }}
                                />
                            )}
                        </StyledDescription>
                    </StyledWrapper>
                )}
            </Card>
        </StyledContent>
    );
};

const StyledContent = styled.div`
    margin-bottom: 2rem;
`;

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledImg = styled.img`
    object-fit: cover;
    margin-right: 1.5rem;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 0;
`;

const StyledDescription = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    max-width: 60%;
`;
