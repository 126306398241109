import React, { useState } from 'react';
import { Flex } from 'antd';

import { ProductsCards } from '../../views/Products/ProductsCards';
import { ProductTabs } from '../../views/Products/ProductTabs';
import { useList } from '../../hooks/useList';
import { ProductsTable } from '../../views/Products/ProductsTable';
import { ProductsActions } from '../../views/Products/ProductsActions';
import { ProductsViewType } from '../../interfaces/products.interface';

export const ProductsContainer = () => {
    const {
        list,
        pagination,
        loading,
        updateFilters,
        filters,
        rowSelection,
        isMultipleActionsAvailable,
        isFiltersEqual,
        setSelectedRowKeys,
    } = useList();

    const [viewType, setViewType] = useState(ProductsViewType.cards);

    const renderProducts = (viewType: ProductsViewType) => {
        switch (viewType) {
            case ProductsViewType.cards:
                return (
                    <ProductsCards
                        list={list}
                        pagination={pagination}
                        isLoading={loading}
                    />
                );
            case ProductsViewType.table:
                return (
                    <Flex gap={16} vertical>
                        <ProductsActions
                            isMultipleActionsAvailable={
                                isMultipleActionsAvailable
                            }
                            rowSelection={rowSelection}
                            updateFilters={updateFilters}
                            setSelectedRowKeys={setSelectedRowKeys}
                            filters={filters}
                            isFiltersEqual={isFiltersEqual}
                        />
                        <ProductsTable
                            rowSelection={rowSelection}
                            updateFilters={updateFilters}
                            filters={filters}
                            list={list}
                            pagination={pagination}
                            isLoading={loading}
                            isMultipleActionsAvailable={
                                isMultipleActionsAvailable
                            }
                        />
                    </Flex>
                );
        }
    };

    return (
        <>
            <ProductTabs
                filters={filters}
                setViewType={setViewType}
                viewType={viewType}
                updateFilters={updateFilters}
            />

            {renderProducts(viewType)}
        </>
    );
};
