import React, { useEffect } from 'react';
import { Checkbox, Flex, Input, Spin, Switch } from 'antd';
import styled from 'styled-components';
import { useFormik } from 'formik';

import { SAVE } from '../../../../shared/contants/actions';
import { GenericModal } from '../../../../shared/ui/GenericModal';
import { ShopImageUpload } from '../../components/ShopImageUpload';
import { ShopForm as Form } from '../../interfaces/shop.interface';

const { TextArea } = Input;

export const ShopForm: React.FC<Form> = props => {
    const {
        open,
        setOpen,
        onSave,
        title,
        initialValues,
        isEdit,
        successReadKeys,
        shopKeys,
        loadingReadKeys,
    } = props;

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSave,
    });

    useEffect(() => {
        if (successReadKeys && shopKeys) {
            formik.setFieldValue('project_id', shopKeys?.project_id);
            formik.setFieldValue('secretKey', shopKeys?.secretKey);
        }
    }, [successReadKeys]);

    const handleChange = (type: string, value: string) => {
        const text = value.length > 1 ? value : value.trim();
        formik.setFieldValue(type, text);
    };

    const disabled =
        formik?.values?.name === '' ||
        formik?.values?.photo === null ||
        formik?.values?.name.length < 1;

    return (
        <GenericModal
            title={title + ' магазин'}
            open={open}
            okText={SAVE}
            okButtonProps={{
                disabled: disabled,
            }}
            onCancel={() => {
                setOpen(!open);
                formik.resetForm();
            }}
            destroyOnClose={true}
            onConfirm={() => onSave(formik.values)}
        >
            <Flex vertical gap={'1.5rem'}>
                <div>
                    <StyledLabel>Фото магазина*</StyledLabel>
                    <ShopImageUpload isEdit={isEdit} formik={formik} />
                </div>

                <div>
                    <StyledLabel>Название*</StyledLabel>
                    <Input
                        value={formik?.values?.name}
                        placeholder=''
                        onChange={event => {
                            handleChange(`name`, event.target.value);
                        }}
                        showCount
                        maxLength={100}
                    />
                </div>

                <div>
                    <StyledLabel>Описание</StyledLabel>
                    <StyledTextArea
                        rows={5}
                        showCount
                        autoSize={{
                            minRows: 3,
                            maxRows: 10,
                        }}
                        maxLength={1000}
                        value={formik?.values?.about}
                        onChange={event => {
                            handleChange(`about`, event.target.value);
                        }}
                    />
                </div>

                <Flex gap={16}>
                    <Switch
                        checked={formik?.values?.price_from}
                        onChange={() =>
                            formik.setFieldValue(
                                'price_from',
                                !formik?.values.price_from
                            )
                        }
                    />
                    <StyledLabel>Указывать цену “от”</StyledLabel>
                </Flex>

                {/* <div>
                    <Checkbox
                        checked={formik?.values?.buyable}
                        onChange={() =>
                            formik.setFieldValue(
                                'buyable',
                                !formik?.values.buyable
                            )
                        }
                    >
                        Есть интеграция с Unitpay
                    </Checkbox>
                </div>*/}

                {/* {formik?.values?.buyable ? (
                    <Flex gap={'1.5rem'} vertical>
                        <div>
                            <StyledLabel>
                                ID проекта в системе Unitpay
                            </StyledLabel>
                            {loadingReadKeys && isEdit ? (
                                <Spin />
                            ) : (
                                <Input
                                    value={formik?.values?.project_id}
                                    placeholder=''
                                    onChange={event => {
                                        handleChange(
                                            `project_id`,
                                            event.target.value
                                        );
                                    }}
                                />
                            )}
                        </div>

                        <div>
                            <StyledLabel>Секретный ключ Unitpay</StyledLabel>
                            {loadingReadKeys && isEdit ? (
                                <Spin />
                            ) : (
                                <Input
                                    value={formik?.values?.secretKey}
                                    placeholder=''
                                    onChange={event => {
                                        handleChange(
                                            `secretKey`,
                                            event.target.value
                                        );
                                    }}
                                />
                            )}
                        </div>
                    </Flex>
                ) : null}*/}
            </Flex>
        </GenericModal>
    );
};

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 0.5rem;
`;

const StyledTextArea = styled(TextArea)`
    margin: 0 0 20px 0;
`;
