import React, { useEffect, useState } from 'react';
import { Flex, Select, Tabs, TabsProps, Typography } from 'antd';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
const { Text } = Typography;

import { Page } from 'shared/ui/Page';
import getEnvConfig from 'utils/config/env';

import { useLazyReadShopsQuery } from '../../shops/services/shops';
import { useProfileData } from '../../../hooks/useProfileData';

export const CrmPage: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const tabMatch = location.pathname.match(/crm\/([^\/]+)/);
    const initialTab = tabMatch ? tabMatch[1] : null;

    const [getShops, { data: shops, isFetching, isLoading }] =
        useLazyReadShopsQuery();
    const { user } = useProfileData();

    const [shopId, setShopId] = useState(id || null);
    const [tab, setTab] = useState(initialTab);

    useEffect(() => {
        if (tab && shopId) {
            navigate(`${getEnvConfig().PUBLIC_PATH}/crm/${tab}/${shopId}`);
        }
    }, [shopId, tab]);

    useEffect(() => {
        if (user) {
            if (user.subscription?.subscription_id !== 3) {
                return navigate('/pay/subscription');
            }
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            getShops(
                {
                    name: null,
                    limit: 100, //TODO: сделать пагинацию
                    offset: 0,
                },
                true
            );
        }
    }, [user]);

    useEffect(() => {
        if (!isLoading && !id && shops?.items.length > 0) {
            setShopId(shops?.items[0].id);
        }
    }, [isLoading, shops]);

    useEffect(() => {
        if (user) {
            getShops(
                {
                    name: null,
                    limit: 100,
                    offset: 0,
                },
                true
            );
        }
    }, [shopId]);

    const tabs: TabsProps['items'] = [
        {
            label: <div>Посетители</div>,
            key: 'visitors',
        },
        {
            label: <div>Заказы</div>,
            key: 'orders',
        },
        // {
        //     label: (
        //         <div>
        //             ИИ запросы
        //         </div>
        //     ),
        //     key: 'ai-knowledge',
        // },
        // {
        //     label: (
        //         <div>
        //             Переходы
        //         </div>
        //     ),
        //     key: 'events',
        // },
    ];

    return (
        <Page title='CRM'>
            <Flex gap={16} align={'baseline'}>
                <Text>Магазин</Text>
                <StyledSelect
                    style={{ width: 300 }}
                    loading={isLoading}
                    disabled={isLoading}
                    placeholder={'Выберите магазин'}
                    fieldNames={{
                        label: 'name',
                        value: 'id',
                    }}
                    onChange={(e, shop: { id: number }) => {
                        setShopId(shop.id);
                    }}
                    value={shopId}
                    options={shops?.items}
                />
            </Flex>
            <Tabs activeKey={tab} onChange={setTab} items={tabs} />
            <Outlet />
        </Page>
    );
};

const StyledSelect = styled(Select)`
    max-width: 300px;
    margin-bottom: 24px;
`;
