import React, { FC, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Flex, Row } from 'antd';

import StaticText from 'shared/layouts/LandingLayout/content.json';
import DurovPhoto from 'static/landing/DurovPhoto.png';

import { SubTitle, Title } from '../ui/Title';
import { Container } from '../ui/Container';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenProps } from '../types/landing.interface';
import { DoubleQuotes } from '../../../ui/Icons/DoubleQuotes';

export const TelegramAdvantages: FC<ScreenProps> = () => {
    return (
        <Container maxWidth={1048} marginTop={130} mobileMarginTop={336}>
            <StyledCard>
                <Flex justify={'space-between'}>
                    <StyledTitle>
                        <SubTitle
                            id={'telegram-advantages'}
                            type='primary'
                            text={StaticText.telegramAdvantages.title}
                        />
                    </StyledTitle>

                    <StyledQuoteShadow>
                        <StyledQuote>
                            <StyledQuoteTitle>
                                {StaticText.telegramAdvantages.title}
                            </StyledQuoteTitle>
                            <StyledSorse>
                                <StyledSorseTag>
                                    {StaticText.telegramAdvantages.quote.sourse}
                                </StyledSorseTag>
                            </StyledSorse>
                            <StyledIcon>
                                <DoubleQuotes />
                            </StyledIcon>
                            <StyledText>
                                {StaticText.telegramAdvantages.quote.text}
                            </StyledText>
                            <StyledAuthor>
                                <StyledAuthorPhoto src={DurovPhoto} />
                                <StyledAuthorName>
                                    {StaticText.telegramAdvantages.quote.author}
                                </StyledAuthorName>
                                <StyledAuthorProfession>
                                    {
                                        StaticText.telegramAdvantages.quote
                                            .authorProfession
                                    }
                                </StyledAuthorProfession>
                            </StyledAuthor>
                        </StyledQuote>
                    </StyledQuoteShadow>
                </Flex>
                <StyledOptions>
                    {StaticText.telegramAdvantages.options.map(
                        (option, index) => (
                            <StyledOption key={`${option.title}${index}`}>
                                <StyledOptionTitle>
                                    {option.title}
                                </StyledOptionTitle>
                                <StyledOptionDescription>
                                    {option.description}
                                </StyledOptionDescription>
                            </StyledOption>
                        )
                    )}
                </StyledOptions>
            </StyledCard>
        </Container>
    );
};

const StyledCard = styled.div`
    background: #f3f4f6;
    border-radius: 30px;
    padding: 24px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 920px) {
        padding: 10px;
        gap: 10px;
    }
`;

const StyledTitle = styled.div`
    display: block;
    width: 100%;
    flex-basis: 250px;

    @media (max-width: 920px) {
        display: none;
    }
`;

const StyledQuoteShadow = styled.div`
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
`;

const StyledQuote = styled.div`
    background: white;
    clip-path: path(
        'M0 30C0 13.4314 13.4315 0 30 0H650C666.569 0 680 13.4315 680 30V272C680 288.569 666.569 302 650 302H162.659C146.09 302 132.659 288.569 132.659 272V243.664C132.659 227.095 119.227 213.664 102.659 213.664H30C13.4315 213.664 0 200.232 0 183.664V30Z'
    );

    width: 680px;
    height: 302px;
    padding: 32px 24px 24px 24px;

    flex: 0 0 680px;
    display: grid;
    grid-auto-rows: minmax(20%, auto);
    //grid-auto-rows: auto;
    //grid-template-columns: 1fr;
    grid-template-areas:
        'sorse sorse sorse sorse icon'
        'text text text text text'
        'title auth auth auth auth';
    @media (max-width: 920px) {
        background: transparent;
        clip-path: none;
        border-radius: 24px;
        width: 100%;
        height: auto;
        padding: 0;
        grid-auto-rows: minmax(auto, auto);
        grid-template-areas:
            'title title sorse icon'
            'text text text text'
            'auth auth auth  auth';
    }
`;

const StyledQuoteTitle = styled.div`
    grid-area: title;
    background-clip: padding-box;
    margin: 0;
    border: none;

    color: #1f2937;

    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;

    display: none;

    @media (max-width: 920px) {
        display: block;

        padding: 10px;
        border-bottom-right-radius: 24px;
    }
`;

const StyledSorse = styled.div`
    grid-area: sorse;
    position: relative;

    @media (max-width: 920px) {
        background: #ffffff;
        padding: 10px 0 0 10px;
        border-top-left-radius: 24px;
        min-height: 70px;
    }

    &:before {
        display: none;
        content: '';
        width: 32px;
        height: 32px;
        transform: rotate(-90deg);
        background-color: #ffffff;
        position: absolute;
        z-index: 1;
        left: -32px;
        top: 38px;
        clip-path: path(
            'm 0.05511438,2.0136258 c 0,6.901815 2.33067402,13.2592852 6.24792002,18.3282852 5.4864946,7.099641 14.0853366,11.671715 23.7520796,11.671715 H 0.05391438 Z'
        );
        @media (max-width: 920px) {
            display: block;
        }
    }
`;

const StyledSorseTag = styled.div`
    display: inline-block;
    background: #e9e1ff;
    color: #a36aff;
    font-size: 12px;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 30px;

    @media (max-width: 920px) {
        font-size: 10px;
        line-height: 13px;

        padding: 4px 8px;
        max-width: 100px;
        min-width: 100px;
    }
`;

const StyledIcon = styled.div`
    grid-area: icon;
    text-align: right;
    padding-right: 1px;

    @media (max-width: 920px) {
        background: #ffffff;
        padding: 18px 10px 16px 8px;
        border-top-right-radius: 24px;

        svg {
            width: 16px;
        }
    }
`;

const StyledText = styled.div`
    grid-area: text;

    height: 220px;
    font-size: 20px;
    line-height: 136%;
    @media (max-width: 920px) {
        background: #ffffff;
        padding: 16px;
        height: auto;
        border-top-left-radius: 24px;
        font-size: 12px;
        line-height: 16px;
    }
`;

const StyledAuthor = styled.div`
    grid-area: auth;
    display: flex;
    height: 52px;
    align-items: center;
    gap: 20px;
    padding-left: 36px;

    @media (max-width: 920px) {
        background: #ffffff;
        padding: 0 16px 16px 16px;
        height: 42px;
        gap: 8px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
    }
`;
const StyledAuthorPhoto = styled.img`
    width: 52px;
    height: 52px;
    flex-shrink: 0;

    @media (max-width: 920px) {
        width: 25px;
        height: 25px;
    }
`;
const StyledAuthorName = styled.div`
    flex: 1 0 auto;
    color: rgba(101, 100, 139, 1);
    font-weight: 700;

    @media (max-width: 920px) {
        font-size: 12px;
    }
`;
const StyledAuthorProfession = styled.div`
    display: inline-block;
    color: #1f2937;
    background: #ceaaf6;

    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 30px;
    padding: 0 8px;

    @media (max-width: 920px) {
        font-size: 10px;
    }
`;

const StyledOptions = styled.div`
    background: #ffffff;
    padding: 32px 30px;
    display: flex;
    gap: 60px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    z-index: 2;
    @media (max-width: 920px) {
        flex-wrap: wrap;
        gap: 16px 0;
        padding: 32px 0;
        justify-content: space-between;
    }
`;

const StyledOption = styled.div`
    display: block;
    @media (max-width: 920px) {
        flex: 0 0 50%;
        padding: 0 10px;
    }
`;

const StyledOptionTitle = styled.h4`
    color: #2357ff;

    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    margin: 0;

    @media (max-width: 920px) {
        font-size: 24px;
        line-height: 32px;
    }
`;

const StyledOptionDescription = styled.p`
    color: #1f2937;

    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    margin: 10px 0;

    @media (max-width: 920px) {
        font-size: 12px;
        line-height: 17px;
        margin: 8px 0 0;
    }
`;
