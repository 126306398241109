import React, { useEffect, useMemo } from 'react';
import { TreeSelect } from 'antd';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { useLazyReadProductCategoryQuery } from '../../services/categories';
import { IProducts } from '../../interfaces/products.interface';
import { getCategoryState } from '../../store/categorySlice';

type ProductCategoriesSelectorProps = {
    shopId: number;

    width?: number;
    formik?: FormikProps<IProducts>;
    type?: string;
    isDisabled: boolean;
};
export const ProductCategoriesSelector: React.FC<
    ProductCategoriesSelectorProps
> = props => {
    const { formik, shopId, isDisabled } = props;

    const { nestedCategoryList } = useSelector(getCategoryState);

    const [getCategories] = useLazyReadProductCategoryQuery();

    useEffect(() => {
        if (shopId) {
            getCategories({ shop_id: +shopId });
        }
    }, [shopId]);

    const onChange = newValue => {
        let newValuesToSave = [];
        if (newValue?.length === 0) {
            newValuesToSave = [0];
        } else if (newValue?.length >= 2) {
            newValuesToSave = newValue.filter(function (num) {
                return num !== 0;
            });
        } else {
            newValuesToSave = newValue;
        }

        formik.setFieldValue('category_ids', newValuesToSave);
    };

    const dropdownItems = useMemo(() => {
        return nestedCategoryList.filter(category => category.id !== null);
    }, [nestedCategoryList, props.type]);

    return (
        <TreeSelect
            maxTagCount={'responsive'}
            placeholder={'Выберите категорию'}
            bordered={true}
            treeCheckable={false}
            showSearch={true}
            style={{ width: props?.width || 300 }}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeData={dropdownItems}
            filterTreeNode={(search, item) => {
                return (
                    item.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                );
            }}
            fieldNames={{
                label: 'name',
                value: 'id',
                children: 'childCategoryList',
            }}
            multiple
            onChange={onChange}
            defaultValue={[0]}
            value={formik.values.category_ids}
            disabled={isDisabled}
        />
    );
};
