import { Avatar, Space, Table } from 'antd';
import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import {
    IStatisticFilters,
    IStatisticMessages,
} from 'features/analytics/services/interfaces';
import { TableFilter } from 'shared/ui/TableFilter';

type Props = {
    readonly source: IStatisticMessages['posts'];
    readonly filters: IStatisticFilters;
    readonly onFiltersUpdate: (update) => void;
};

export const PostsTableView: React.FC<Props> = props => {
    return (
        <Fragment>
            <StyledTable
                dataSource={props.source.posts}
                rowKey={post => post.post_id}
                rowSelection={false}
                pagination={{
                    total: props.source.total_messages,
                    showSizeChanger: true,
                    // pageSize: props.filters?.limit || 10,
                    showTotal: () => `Постов: ${props.source.total_messages}`,
                    onChange: (page, pageSize) => {
                        props.onFiltersUpdate({
                            offset: (page - 1) * pageSize,
                            limit: pageSize,
                        });
                    },
                    onShowSizeChange: (current, size) => {
                        props.onFiltersUpdate({
                            offset: (current - 1) * size,
                            limit: size,
                        });
                    },
                }}
            >
                <Table.Column
                    title={'Текст поста'}
                    filterSearch={false}
                    render={post => (
                        <Space>
                            <span>{post.text}</span>
                        </Space>
                    )}
                />
                <Table.Column
                    width={'11%'}
                    title={'Просмотры'}
                    dataIndex={'views_count'}
                />
                <Table.Column
                    width={'11%'}
                    title={'Реакции'}
                    dataIndex={'reactions_count'}
                />
                <Table.Column
                    width={'16%'}
                    title={'Дата создания'}
                    dataIndex={'created_at'}
                />
                {/*<Table.Column*/}
                {/*    width={'16%'}*/}
                {/*    filterSearch={false}*/}
                {/*    title={*/}
                {/*        <TableFilter<string> title='Дата создания'>*/}
                {/*            <TableFilter.Text />*/}
                {/*        </TableFilter>*/}
                {/*    }*/}
                {/*    dataIndex={'created_at'}*/}
                {/*/>*/}
            </StyledTable>
        </Fragment>
    );
};

const StyledTable = styled(Table<any>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;
