import React, { useEffect, useState } from 'react';
import { Card, Col, Flex, Image, Row } from 'antd';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';

import { getSrc } from '../../../../utils/common/getSrc';
import { useTheme } from '../../../../hooks/useTheme';
import { ListControls } from './ListControls';
import { BannerLink } from './BannerLink';
import { BannerProps } from '../../interfaces/banners.interface';

interface BannersListProps {
    readonly banners: BannerProps[];
    readonly updatePosition: (items: BannerProps[]) => void;
}

export const BannersList = (props: BannersListProps) => {
    const { boxShadow } = useTheme();
    const { banners, updatePosition } = props;

    const [items, setItems] = useState<BannerProps[]>(banners);

    useEffect(() => {
        if (banners) {
            setItems(banners);
        }
    }, [banners]);

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        setItems(array => arrayMoveImmutable(array, oldIndex, newIndex));
        void updatePosition(items);
    };

    return (
        <SortableList style={{ cursor: 'move' }} onSortEnd={onSortEnd}>
            {items.map(banner => (
                <SortableItem key={banner.id}>
                    <Card
                        type='inner'
                        style={{ boxShadow, marginBottom: '1.5rem' }}
                    >
                        <Row gutter={24}>
                            <Col span={10}>
                                <Image
                                    preview={false}
                                    src={getSrc(
                                        `/storage/${banner.images?.original}`
                                    )}
                                />
                            </Col>

                            <Col
                                span={11}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <BannerLink url={banner.url} />
                            </Col>

                            <Col
                                span={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <ListControls bannerId={banner.id} />
                            </Col>
                        </Row>
                    </Card>
                </SortableItem>
            ))}
        </SortableList>
    );
};
