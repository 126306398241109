import { App } from 'antd';
import { useEffect, useState } from 'react';

import { useUpdateAllProductsTypeMutation } from '../services/products';

export const useUpdateProductsType = () => {
    const { notification } = App.useApp();
    const [notificationMessage, setNotificationMessage] = useState('');

    const [updateAllProducts, { isSuccess: isSuccessUpdateAllProductsType }] =
        useUpdateAllProductsTypeMutation();

    useEffect(() => {
        if (isSuccessUpdateAllProductsType) {
            notification.success({
                message: notificationMessage,
            });
            setNotificationMessage('');
        }
    }, [isSuccessUpdateAllProductsType]);

    const updateAllProductsType = (type, shop_id) => {
        if (type === 'product') {
            setNotificationMessage('Все витрины переведены в товары');
        }
        if (type === 'link') {
            setNotificationMessage('Все товары переведены в витрины');
        }
        updateAllProducts({ shop_id: shop_id, convert_to: type });
    };

    return {
        updateAllProductsType,
    };
};
