import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Card, Typography, Button, Skeleton, Space, App } from 'antd';
import { useParams } from 'react-router-dom';

import { useTheme } from '../../../../../../../hooks/useTheme';
import { useReadAuthorByIdQuery } from '../../../../../services/author';
import {
    useCreateWebinarUserMutation,
    useLazyReadWebinarHistoryQuery,
} from '../../../../../services/webinars';

type TProps = {
    readonly action: string;
    readonly id: number;
    readonly link: string;
    readonly uuid: string;
};

export const WebinarAuthorPreviewContainer = (props: TProps) => {
    const { notification } = App.useApp();
    const { id } = useParams();
    const token = useTheme();

    const { data: author, isSuccess } = useReadAuthorByIdQuery(
        Number(props.id)
    );

    const [createWebinarUser, { isSuccess: isSuccessCreate, data: response }] =
        useCreateWebinarUserMutation();

    const [
        getWebinarHistory,
        { data: webinar, error, isError: isErrorWebinar, isLoading },
    ] = useLazyReadWebinarHistoryQuery();

    useEffect(() => {
        if (isErrorWebinar && error) {
            notification.error({
                message: error?.data?.message || 'Произошла ошибка',
            });
        }
    }, [isErrorWebinar, error]);

    useEffect(() => {
        if (isSuccessCreate) {
            window.open(response.redirectUrl);
        }
    }, [isSuccessCreate]);

    return (
        <StyledContent>
            <Card type='inner' style={token.Card}>
                {isSuccess && author ? (
                    <StyledWrapper direction='vertical' size='large'>
                        <Typography.Text>{author.about}</Typography.Text>

                        <Button
                            loading={isLoading}
                            type={'primary'}
                            style={{ maxWidth: 'max-content' }}
                            onClick={() =>
                                props.action ===
                                ('Смотреть запись' || 'Смотреть вебинар')
                                    ? getWebinarHistory(+id)
                                    : createWebinarUser(props.uuid)
                            }
                        >
                            {props.action}
                        </Button>
                        {webinar?.embed && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: webinar.embed,
                                }}
                            />
                        )}
                    </StyledWrapper>
                ) : (
                    <Skeleton />
                )}
            </Card>
        </StyledContent>
    );
};

const StyledContent = styled.div`
    margin: 24px 0;
`;

const StyledWrapper = styled(Space)`
    max-width: 60%;
`;
