import React from 'react';
import styled from 'styled-components';
import { Button, Card, List, Skeleton, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '../../../../../hooks/useTheme';
import postPlaceholder from '../../../../../static/images/post_placeholder.png';
import { getSrc } from '../../../../../utils/common/getSrc';

interface IWebinarList {
    readonly title: string;
    readonly isFetching: boolean;
    readonly isError: boolean;
    readonly filters: {
        limit: number;
        offset: number;
    };
    readonly onFiltersUpdate: (update, type) => void;
    readonly data: {
        webinars: IWebinar[];
        total: number;
    };
}
interface IWebinar {
    readonly background_image: string;
    readonly title: string;
    readonly description: string;
    readonly uuid: string;
    readonly start_at: string;
}
export const WebinarList: React.FC<IWebinarList> = props => {
    const { title, isFetching, isError, data, filters, onFiltersUpdate } =
        props;

    const navigate = useNavigate();
    const token = useTheme();

    const pagination = {
        current: filters?.offset / filters?.limit + 1,
        showLessItems: true,
        showTitle: true,
        defaultPageSize: 3,
        total: data.total,
        showTotal: () => `Вебинаров: ${data.total}`,
        onChange: (page, pageSize) => {
            onFiltersUpdate(
                {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                },
                'webinar'
            );
        },
    };

    return (
        <StyledWrapper>
            <StyledPageTitle level={2}>{title}</StyledPageTitle>
            {isFetching || isError ? (
                <Skeleton />
            ) : (
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 3,
                        xl: 3,
                        xxl: 3,
                    }}
                    loading={isFetching}
                    dataSource={isError ? [] : data?.webinars}
                    renderItem={(webinar, index) => (
                        <List.Item>
                            <Card
                                type='inner'
                                style={token.Card}
                                key={index}
                                cover={
                                    <StyledCover
                                        src={
                                            !webinar.background_image
                                                ? postPlaceholder
                                                : getSrc(
                                                      `/storage/${webinar.background_image}`
                                                  )
                                        }
                                        alt='post image'
                                    />
                                }
                            >
                                <StyledType>
                                    <StyledDescription type={'secondary'}>
                                        {webinar.start_at}
                                    </StyledDescription>
                                </StyledType>

                                <StyledTitle level={5}>
                                    {webinar?.title}
                                </StyledTitle>
                                <StyledDescription
                                    ellipsis={{
                                        rows: 2,
                                    }}
                                >
                                    {webinar?.description}
                                </StyledDescription>
                                <StyledActions>
                                    <StyledButton
                                        onClick={() =>
                                            navigate(
                                                `/courses/member/webinar-preview/${webinar.uuid}/${webinar.id}`
                                            )
                                        }
                                    >
                                        Смотреть
                                    </StyledButton>
                                </StyledActions>
                            </Card>
                        </List.Item>
                    )}
                    pagination={pagination}
                />
            )}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    margin: 1.5rem 0;
`;

const StyledPageTitle = styled(Typography.Title)`
    margin: 0;
`;

const StyledButton = styled(Button)`
    width: 100%;
`;

const StyledTitle = styled(Typography.Title)`
    margin: 1rem 0;
`;

const StyledDescription = styled(Typography.Paragraph)`
    margin: 1rem 0;
`;

const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
`;

const StyledCover = styled.img`
    object-fit: cover;
    width: 150px;
    height: 250px;
`;

const StyledType = styled.div`
    justify-content: flex-end;
    display: flex;
`;
