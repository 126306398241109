import React from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';

import { useTheme } from '../../../../hooks/useTheme';
import { productActions, ProductActionsEnum } from '../../utils/products';
import { ProductProps } from '../../interfaces/products.interface';
import { useManageProducts } from '../../hooks/useManageProducts';

export const ProductActions = (props: {
    item: ProductProps;
    isMultipleActionsAvailable: boolean;
}) => {
    const { item, isMultipleActionsAvailable } = props;

    const { colorBorder, colorIcon, colorIconHover } = useTheme();
    const { manageProduct, changeProductStatus, deleteProduct } =
        useManageProducts();

    const handleAction = (type: ProductActionsEnum, item: ProductProps) => {
        if (isMultipleActionsAvailable) {
            return;
        } else {
            switch (type) {
                case ProductActionsEnum.archive:
                    return changeProductStatus(item.id, item.status);
                case ProductActionsEnum.delete:
                    return deleteProduct(item.id);
                case ProductActionsEnum.edit:
                    return manageProduct('edit', item.shop_id, item.id);
            }
        }
    };

    return (
        <Flex gap={16}>
            {productActions
                .sort((a, b) => a.position - b.position)
                .map((action, index) => (
                    <StyledIcon
                        $colorDefault={
                            isMultipleActionsAvailable ? colorBorder : colorIcon
                        }
                        $colorActive={
                            isMultipleActionsAvailable
                                ? colorBorder
                                : colorIconHover
                        }
                        $isAvailable={!isMultipleActionsAvailable}
                        key={index}
                        onClick={() => handleAction(action.type, item)}
                    >
                        {action.component}
                    </StyledIcon>
                ))}
        </Flex>
    );
};

const StyledIcon = styled.div<{
    $colorDefault: string;
    $colorActive: string;
    $isAvailable: boolean;
}>`
    font-size: 24px;
    cursor: ${props => (props.$isAvailable ? 'pointer' : 'not-allowed')};
    color: ${props => props.$colorDefault};
    &:hover {
        color: ${props => props.$colorActive};
    }
`;
