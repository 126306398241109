import React from 'react';

export const Sitilink = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='183'
            height='33'
            viewBox='0 0 183 33'
            fill='none'
        >
            <g clipPath='url(#clip0_460_1242)'>
                <path
                    d='M0.5 6.68455C0.5 6.35132 0.563253 6.02295 0.680027 5.71161C1.64098 3.16737 3.27825 1.27257 6.13922 0.803125C7.37995 0.60124 8.72529 0.752046 9.98304 0.97339C15.0092 1.86606 20.0183 2.85116 25.0348 3.79735C27.8738 4.33247 29.6425 6.30997 29.6741 9.20446C29.7252 14.1932 29.7228 19.182 29.6741 24.1707C29.6473 26.9655 27.942 29.0135 25.2732 29.5292C19.9429 30.5581 14.6078 31.5651 9.26537 32.5307C5.05906 33.2896 2.20782 31.6064 0.714086 27.5882C0.714086 27.5857 0.714086 27.5857 0.711653 27.5833C0.585148 27.2476 0.5 26.8974 0.5 26.5374C0.5 19.9214 0.5 13.303 0.5 6.68455ZM6.83744 16.9612C6.835 16.9612 6.835 16.9612 6.83257 16.9612C6.83257 18.7855 6.82041 20.6122 6.835 22.4364C6.85933 25.3991 8.56472 27.0068 11.4841 26.6225C14.9362 26.1701 18.364 25.5255 21.8113 25.022C23.1226 24.8299 22.8866 23.9129 22.8355 23.0956C22.7723 22.0789 22.0059 22.2929 21.3758 22.3854C18.0891 22.8694 14.8048 23.3632 11.5206 23.8594C9.89546 24.105 9.52324 23.818 9.51351 22.1956C9.49892 19.1552 9.50622 16.1123 9.50622 13.0719C9.50622 9.71039 9.50378 9.72985 12.8391 10.2625C15.6782 10.715 18.5246 11.143 21.3758 11.5201C21.8259 11.5809 22.5557 11.4763 22.7455 11.1844C23.3488 10.2528 22.692 9.05366 21.5802 8.8761C18.1791 8.33125 14.7805 7.78397 11.3746 7.27074C8.86152 6.8913 6.90799 8.54773 6.84717 11.1236C6.80094 13.067 6.83744 15.0153 6.83744 16.9612Z'
                    fill={color ? color : '#2357FF'}
                />
                <path
                    d='M105.269 13.5265C104.782 14.0932 104.517 14.3705 104.286 14.6746C102.038 17.5983 99.7417 20.4879 97.5814 23.4748C96.7931 24.5645 95.8735 24.9318 94.6863 24.6788C94.3141 24.5986 93.7619 24.0707 93.757 23.7399C93.6937 19.3617 93.7132 14.9835 93.7156 10.6052C93.7156 9.49123 94.5306 9.60555 95.2629 9.57636C96.0828 9.54231 96.5791 9.81717 96.5742 10.739C96.562 13.456 96.5742 16.1729 96.5766 18.8874C96.5766 19.3641 96.5766 19.8409 96.8515 20.4368C97.1264 20.1255 97.4184 19.8287 97.6738 19.5004C99.9314 16.584 102.167 13.6506 104.447 10.7536C104.804 10.2988 105.288 9.79041 105.809 9.64934C107.673 9.14827 108.225 9.63474 108.227 11.5733C108.232 15.4262 108.235 19.2766 108.222 23.1294C108.218 24.5864 107.232 25.2553 105.919 24.6132C105.614 24.4648 105.403 23.8348 105.396 23.4213C105.349 20.6654 105.371 17.9072 105.364 15.1513C105.366 14.7329 105.318 14.3097 105.269 13.5265Z'
                    fill={color ? color : '#2357FF'}
                />
                <path
                    d='M141.716 13.8233C139.748 16.387 137.779 18.9506 135.811 21.5143C135.193 22.317 134.631 23.1708 133.943 23.9102C133.593 24.2872 133.043 24.635 132.549 24.691C130.634 24.9123 130.462 24.7153 130.462 22.7816C130.459 18.8898 130.483 14.9956 130.449 11.1039C130.44 10.0507 130.751 9.54961 131.902 9.55448C133.021 9.55691 133.386 9.98744 133.364 11.0747C133.313 13.7503 133.347 16.4259 133.349 19.1015C133.349 19.5247 133.349 19.9479 133.349 20.3736C133.454 20.4076 133.559 20.4417 133.666 20.4782C134.505 19.4128 135.349 18.3523 136.179 17.2796C137.889 15.0686 139.643 12.8868 141.278 10.6198C142.1 9.47908 143.161 9.46205 144.292 9.67123C144.577 9.72474 144.968 10.2793 144.971 10.6101C145.015 14.9883 145.002 19.3666 144.998 23.7472C144.995 24.888 144.141 24.6983 143.431 24.7348C142.635 24.7737 142.103 24.5791 142.11 23.6135C142.134 20.8576 142.112 18.0993 142.11 15.3435C142.11 14.874 142.11 14.407 142.11 13.9376C141.981 13.8938 141.847 13.8573 141.716 13.8233Z'
                    fill={color ? color : '#2357FF'}
                />
                <path
                    d='M62.2337 20.5001C62.6497 19.982 63.0755 19.4712 63.4842 18.9458C65.6664 16.1291 67.8389 13.3052 70.0235 10.4885C70.6779 9.64448 72.0014 9.24314 72.9283 9.68826C73.2081 9.82204 73.4927 10.2939 73.4951 10.6126C73.5316 14.9884 73.5195 19.3617 73.5195 23.7375C73.5195 24.7713 72.7823 24.7032 72.0744 24.7299C71.2497 24.764 70.7655 24.5061 70.7728 23.577C70.7899 20.7409 70.7728 17.9047 70.7704 15.0686C70.7704 14.6405 70.7704 14.2124 70.7704 13.7843C70.6707 13.7503 70.5709 13.7162 70.4712 13.6822C70.1792 14.0349 69.8751 14.3803 69.5954 14.7451C67.3523 17.6688 65.0631 20.5584 62.9003 23.5405C62.1388 24.5913 61.2387 24.9221 60.105 24.674C59.7352 24.5937 59.1976 24.0635 59.1927 23.7351C59.1319 19.3617 59.1562 14.9859 59.1465 10.6101C59.1441 9.58854 59.8325 9.5934 60.5575 9.57638C61.3701 9.55692 61.8834 9.80502 61.881 10.7317C61.8737 13.4876 61.8834 16.241 61.8955 18.9969C61.898 19.4639 61.9369 19.9309 61.9588 20.3979C62.0488 20.432 62.1413 20.466 62.2337 20.5001Z'
                    fill={color ? color : '#2357FF'}
                />
                <path
                    d='M163.514 17.2091C163.514 19.3179 163.484 21.4292 163.528 23.5356C163.55 24.5572 163.017 24.7421 162.159 24.7421C161.285 24.7421 160.626 24.6302 160.648 23.5259C160.674 22.2294 160.616 20.9281 160.648 19.6317C160.67 18.6879 160.251 18.3279 159.332 18.3401C157.385 18.3693 155.439 18.3766 153.493 18.3328C152.471 18.3109 152.126 18.7779 152.145 19.729C152.172 20.9452 152.113 22.1638 152.15 23.3799C152.179 24.365 151.775 24.7494 150.78 24.7421C149.834 24.7348 149.36 24.4769 149.369 23.4359C149.401 19.2571 149.401 15.0808 149.369 10.902C149.362 9.88526 149.795 9.55933 150.761 9.55933C151.736 9.55933 152.179 9.92175 152.15 10.919C152.116 12.0938 152.162 13.2711 152.15 14.4484C152.143 15.2754 152.425 15.7424 153.349 15.7278C155.376 15.6961 157.405 15.6986 159.431 15.7278C160.329 15.7399 160.66 15.3337 160.645 14.4751C160.623 13.2176 160.665 11.9601 160.655 10.7025C160.648 9.65905 161.317 9.56906 162.124 9.56662C162.959 9.56419 163.543 9.71743 163.523 10.756C163.487 12.9087 163.514 15.0589 163.514 17.2091Z'
                    fill={color ? color : '#2357FF'}
                />
                <path
                    d='M170.575 17.9485C170.575 20.0039 170.538 21.6992 170.589 23.3921C170.618 24.3894 170.239 24.7518 169.241 24.7494C168.254 24.7469 167.872 24.404 167.879 23.3994C167.913 19.2255 167.913 15.0516 167.881 10.8777C167.874 9.83663 168.349 9.55934 169.29 9.56421C170.219 9.56907 170.609 9.91933 170.584 10.8631C170.543 12.4344 170.572 14.0081 170.572 15.898C171.168 15.5064 171.533 15.307 171.852 15.0492C173.737 13.5143 175.654 12.0184 177.472 10.4058C178.863 9.1726 180.371 9.67123 181.86 9.62502C181.914 9.7442 181.965 9.86582 182.018 9.985C179.291 12.2203 176.564 14.4557 173.659 16.8369C176.708 19.4298 179.605 21.8938 182.503 24.3578C182.447 24.4599 182.391 24.5621 182.335 24.6642C180.71 24.7007 179.109 25.068 177.637 23.6791C175.815 21.9595 173.796 20.449 171.854 18.8534C171.55 18.6077 171.214 18.4009 170.575 17.9485Z'
                    fill={color ? color : '#2357FF'}
                />
                <path
                    d='M126.086 17.158C126.086 19.2668 126.057 21.3757 126.099 23.4845C126.121 24.5669 125.539 24.7348 124.639 24.7421C123.702 24.7494 123.33 24.3918 123.337 23.4529C123.362 20.2082 123.347 16.9634 123.337 13.7187C123.333 12.1449 123.257 12.0744 121.681 12.0671C120.545 12.0598 119.408 12.1036 118.275 12.0574C117.414 12.0233 117.015 12.376 116.937 13.2273C116.752 15.2462 116.594 17.2674 116.299 19.2717C116.131 20.4222 115.825 21.5776 115.392 22.6575C114.772 24.2021 113.487 24.9099 111.838 24.9512C111.271 24.9658 110.731 24.8929 110.753 24.1461C110.772 23.5064 110.434 22.6162 111.436 22.4557C113.047 22.1978 113.538 21.0887 113.699 19.712C114.027 16.8953 114.365 14.0762 114.592 11.2498C114.686 10.0409 115.083 9.51798 116.36 9.55203C119.075 9.625 121.793 9.61771 124.508 9.55447C125.741 9.52528 126.155 9.99715 126.113 11.1987C126.045 13.1835 126.094 15.1732 126.094 17.1604C126.091 17.158 126.089 17.158 126.086 17.158Z'
                    fill={color ? color : '#2357FF'}
                />
                <path
                    d='M41.1148 18.9312C40.6112 16.7542 40.9591 14.4435 42.2023 12.5876C43.0732 11.2863 44.2969 10.2696 45.7882 9.69311C48.8195 8.51828 52.3568 9.27961 54.4101 11.5514C54.5731 11.7314 54.7823 11.9041 54.8626 12.1182C55.0961 12.7603 55.4854 13.4462 55.41 14.0665C55.354 14.5213 53.0964 14.317 52.6755 13.7065C51.1574 11.5076 47.6688 11.3301 45.9026 12.6557C43.8493 14.1954 43.1876 17.4669 44.4843 19.9309C45.9877 22.7864 49.8024 23.4213 52.1524 21.2006C52.3884 20.9792 52.9358 20.2665 53.1937 20.2179C53.9478 20.0744 54.4101 20.2179 55.1375 20.2495C55.2737 20.2544 55.3783 20.3687 55.3808 20.5049C55.3881 21.1762 55.2275 21.6384 54.938 22.0592C53.2861 24.4672 50.8387 25.3064 48.0532 25.0923C45.0122 24.8588 42.8032 23.2535 41.5917 20.4465C41.3751 19.9649 41.234 19.4517 41.1148 18.9312Z'
                    fill={color ? color : '#2357FF'}
                />
                <path
                    d='M83.7781 9.57881C85.8435 9.57881 87.909 9.59584 89.9744 9.57151C90.9013 9.55935 91.2711 9.93394 91.2638 10.8777C91.2565 11.7923 90.8599 12.0939 90.006 12.0695C88.8723 12.0379 87.7362 12.1182 86.605 12.0525C85.5029 11.9893 85.0893 12.4222 85.1015 13.5241C85.1404 16.764 85.0893 20.0063 85.1234 23.2462C85.1356 24.3237 84.7658 24.7664 83.637 24.7542C82.569 24.7445 82.2259 24.348 82.2405 23.3119C82.2819 20.072 82.2162 16.8296 82.2722 13.5898C82.2916 12.432 81.8975 11.9771 80.7322 12.0476C79.562 12.1182 78.3846 12.0355 77.2095 12.0574C76.3288 12.0744 76.1172 11.5879 76.1172 10.8242C76.1172 10.0556 76.358 9.57638 77.2192 9.58124C79.4063 9.59097 81.5934 9.58368 83.7805 9.58368C83.7781 9.58611 83.7781 9.58124 83.7781 9.57881Z'
                    fill={color ? color : '#2357FF'}
                />
            </g>
            <defs>
                <clipPath id='clip0_460_1242'>
                    <rect
                        width='182'
                        height='32'
                        fill='white'
                        transform='translate(0.5 0.708008)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
