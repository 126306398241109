import React, { useEffect, useState } from 'react';
import { Avatar, Flex, Table, Typography } from 'antd';
import { Outlet, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { useLazyReadVisitorsQuery } from '../../services/visitors';
import { VisitorsFilters } from '../../interfaces/visitors.interface';

const { Title, Text } = Typography;

export const VisitorsContainer = () => {
    const { id } = useParams();

    const [filters, setFilters] = useState<VisitorsFilters>({
        shop_id: +id,
        limit: 10,
        offset: 0,
    });
    const [dataTable, setDataTable] = useState({
        items: [],
        total: 0,
    });

    const [getVisitors, { data, isLoading, isFetching }] =
        useLazyReadVisitorsQuery();

    useEffect(() => {
        if (filters) {
            void getVisitors({ id: +id, filters: filters }, true);
        }
    }, [filters, id]);

    useEffect(() => {
        if (data) {
            const dataItems = data.items.map(elem => ({
                name: elem?.init_data?.initData?.user?.firstName,
                photoUrl: elem?.init_data?.initData?.user?.photoUrl,
                phone: elem.phone,
                telegramUserId: elem.telegram_user_id,
                telegramUsername: elem?.init_data?.initData?.user?.username,
                authDate: elem?.updated_at
                    ? elem?.updated_at
                    : elem?.created_at,
            }));
            setDataTable({
                items: dataItems,
                total: data?.total,
            });
        }
    }, [data]);

    const columns = [
        {
            title: <Text strong>Имя</Text>,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <Flex gap={4} align={'center'}>
                    {record?.photoUrl && (
                        <Avatar
                            src={
                                record.photoUrl
                                    ? record.photoUrl
                                    : record?.name[0]
                            }
                        />
                    )}
                    {record?.name}
                </Flex>
            ),
        },
        {
            title: <Text strong>ID Telegram</Text>,
            dataIndex: 'telegramUserId',
            key: 'telegramUserId',
        },
        {
            title: <Text strong>Юзернэйм</Text>,
            dataIndex: 'telegramUsername',
            key: 'telegramUsername',
            render: telegramUsername => `@${telegramUsername}`,
        },
        {
            title: <Text strong>Телефон</Text>,
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: <Text strong>Дата последнего посещения</Text>,
            dataIndex: 'authDate',
            key: 'authDate',
            render: time => dayjs(time).format('DD.MM.YY HH:mm'),
        },
    ];

    const handleChange = pagination => {
        return setFilters({
            ...filters,
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
        });
    };

    const pagination = {
        total: dataTable?.total,
        // showTotal: () => `Посетителей: ${data?.total}`,
        onChange: (page, pageSize) => {
            setFilters({
                ...filters,
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        showQuickJumper: false,
        showSizeChanger: true,
    };

    return (
        <Flex gap={24} vertical>
            <Title style={{ margin: 0 }} level={4}>
                История посетителей
            </Title>
            <Table
                onChange={handleChange}
                loading={isLoading || isFetching}
                pagination={pagination}
                dataSource={dataTable.items}
                rowKey={record => record.id}
                columns={columns}
            />
            <Outlet />
        </Flex>
    );
};
