import React, { useRef } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

import { Navbar } from 'shared/layouts/LandingLayout/ui/Navbar';
import { Content } from 'shared/layouts/LandingLayout/ui/Content';
import LandingBg1 from 'static/landing/landing-bg-1.webp';
import LandingPhone1 from 'static/landing/landing-phone-1.webp';

import { Footer } from '../../ui/Footer';

export const LandingLayout: React.FC = () => {
    const headingRef = useRef(null);
    const introRef = useRef(null);
    const contactsRef = useRef(null);
    const telegramRef = useRef(null);
    const ecomRef = useRef(null);
    const individualConditionsRef = useRef(null);
    const aboutUsRef = useRef(null);

    const blockProps = {
        headingRef: headingRef,
        introRef: introRef,
        contactsRef: contactsRef,
        telegramRef: telegramRef,
        ecomRef: ecomRef,
        individualConditionsRef: individualConditionsRef,
        aboutUsRef: aboutUsRef,
    };

    return (
        <StyledLayout>
            <Navbar {...blockProps} />
            <Content {...blockProps} />
            <Footer contactsRef={contactsRef} />
        </StyledLayout>
    );
};

const StyledLayout = styled(Layout)`
    background: #ffffff;
    background-image: url(${LandingPhone1}), url(${LandingBg1});
    background-size: 407px 417px, 1535px 1534px;
    background-repeat: no-repeat;
    background-position: calc(50% + 316px) 165px, calc(50% + 291px) -260px;

    @media (max-width: 920px) {
        background-size: 260px 265px, 954px 961px;
        background-position: calc(50% - 2px) 369px, calc(50% - 19px) 88px;
    }

    @media (max-width: 483px) {
        background-position: calc(50% + -2px) 419px, calc(50% + -19px) 138px !important;
    }
    //background-size: cover;
`;
