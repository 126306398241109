import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import qs from 'query-string';

import getEnvConfig from '../../../utils/config/env';
import {
    IAuthors,
    ProductProps,
    IProductFilters,
    IProducts,
    IProductsPublic,
    ProductBuy,
    ProductPay,
    ProductStatus,
} from '../interfaces/products.interface';
import { Shop } from '../interfaces/shop.interface';

export const productsApi = createApi({
    reducerPath: 'products',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Products', 'ProductImage'],

    endpoints: builder => ({
        /*
         * Create product\good
         * */
        createProduct: builder.mutation<IProducts, FormData>({
            query: body => ({
                url: '/products',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: IProducts }) => {
                return response.data;
            },
            invalidatesTags: ['Products'],
        }),
        /*
         * Read product\good list
         * */
        readProducts: builder.query<
            IProductsPublic<ProductProps>,
            IProductFilters
        >({
            query: params => {
                const queryArgs = qs.stringify(params, {
                    arrayFormat: 'bracket',
                    skipNull: true,
                });
                return {
                    url: `/products?${queryArgs}`,
                };
            },
            transformResponse: (res: { data: Array<ProductProps> }, meta) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Products'],
        }),
        /*
         * Read product\good by id
         * */
        readProductById: builder.query({
            query: id => ({
                url: `/products/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: IProducts }) => {
                return response.data;
            },
            providesTags: ['Products'],
        }),
        /*
         * Update product\good by id
         * */
        updateProduct: builder.mutation({
            query: ({ id, body }) => ({
                url: `/products/${id}`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Products'],
        }),
        /*
         * Delete product\good by id
         * */
        deleteProduct: builder.mutation({
            query: id => ({
                url: `/products/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Products'],
        }),

        readAuthors: builder.query<IProductsPublic<IAuthors>, Shop>({
            query: params => {
                const queryArgs = qs.stringify(params, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/shops/list?${queryArgs}`,
                };
            },
            transformResponse: (res: { data: Array<IAuthors> }, meta) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Products'],
        }),

        /*
         * Read products list by author id
         * */
        readProductsByAuthor: builder.query<
            IProductsPublic<ProductProps>,
            IProductFilters
        >({
            query: ({ id, ...args }) => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/public/products/${id}?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (res: { data: Array<ProductProps> }, meta) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Products'],
        }),
        /*
         * Read public product  by  id
         * */
        readProductsPublic: builder.query<ProductProps, number>({
            query: id => ({
                url: `/public/product/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: ProductProps }) => {
                return response.data;
            },
            providesTags: ['Products'],
        }),
        createProductBuy: builder.mutation<ProductPay, ProductBuy>({
            query: body => ({
                url: '/market/product/buy',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: ProductPay }) => {
                return response.data;
            },
            invalidatesTags: ['Products'],
        }),
        createProductPreorder: builder.mutation<ProductPay, ProductBuy>({
            query: body => ({
                url: '/market/product/order/create',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: ProductPay }) => {
                return response.data;
            },
            invalidatesTags: ['Products'],
        }),

        readProductStatus: builder.query<ProductStatus, number>({
            query: id => ({
                url: `/market/show/order/${id}`,
            }),
            transformResponse: (response: { data: ProductStatus }) => {
                return response.data;
            },
        }),

        deleteProductImage: builder.mutation({
            query: ({ id, imageId }) => ({
                url: `/products/image/${id}`,
                body: { image_id: imageId },
                method: 'DELETE',
            }),
            invalidatesTags: ['Products'],
        }),

        createProductImage: builder.mutation({
            query: ({ id, body }) => ({
                url: `/products/image/${id}`,
                body: body,
                method: 'POST',
            }),
            invalidatesTags: ['Products'],
        }),
        updateProductImageCover: builder.mutation({
            query: ({ id, imageId }) => ({
                url: `/products/image/first/${id}`,
                body: { image_id: imageId },
                method: 'PUT',
            }),
            invalidatesTags: ['Products'],
        }),

        updateProductStatus: builder.mutation({
            query: ({ id, ...status }) => ({
                url: `/product/change/status/${id}`,
                body: status,
                method: 'POST',
            }),
            invalidatesTags: ['Products'],
        }),
        updateAllProductsType: builder.mutation({
            query: ({ shop_id, convert_to }) => ({
                url: `/products/change/all`,
                body: {
                    shop_id: shop_id,
                    convert_to: convert_to,
                },
                method: 'POST',
            }),
            invalidatesTags: ['Products'],
        }),

        updateProductsCategories: builder.mutation({
            query: ({ id, ...payload }) => ({
                url: `/products/categories/attach`,
                body: payload,
                method: 'POST',
            }),
            invalidatesTags: ['Products'],
        }),
    }),
});

export const {
    useLazyReadProductsQuery,
    useReadProductsQuery,
    useReadProductByIdQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useReadAuthorsQuery,
    useReadProductsByAuthorQuery,
    useReadProductsPublicQuery,
    useDeleteProductMutation,
    useCreateProductBuyMutation,
    useReadProductStatusQuery,
    useDeleteProductImageMutation,
    useCreateProductPreorderMutation,
    useCreateProductImageMutation,
    useUpdateProductImageCoverMutation,
    useUpdateProductStatusMutation,
    useUpdateAllProductsTypeMutation,
    useUpdateProductsCategoriesMutation,
} = productsApi;
