import React, { FC, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Flex, Row } from 'antd';

import StaticText from 'shared/layouts/LandingLayout/content.json';
import DurovPhoto from 'static/landing/DurovPhoto.png';
import showcasePhones from 'static/landing/showcasePhones.webp';
import showcaseBG from 'static/landing/showcaseBG.webp';
import showcaseBgMobile from 'static/landing/showcaseBgMobile.webp';

import { SubTitle, Title } from '../ui/Title';
import { Container } from '../ui/Container';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenProps } from '../types/landing.interface';
import { DoubleQuotes } from '../../../ui/Icons/DoubleQuotes';
import { DemoEmail } from '../components/DemoEmail';

export const SpodialShowcase: FC<ScreenProps> = () => {
    return (
        <Container maxWidth={1000} marginTop={100} mobileMarginTop={65}>
            <StyledCard>
                <StyledImage src={showcasePhones} />
                <StyledTitle>
                    <SubTitle
                        id={'telegram-advantages'}
                        type='primary'
                        text={StaticText.spodialShowcase.title}
                    />
                </StyledTitle>

                <StyledDescription>
                    {StaticText.spodialShowcase.description}
                </StyledDescription>

                <StyledButton
                    href='https://marketplace.spodial.com/'
                    target='_blank'
                >
                    {StaticText.spodialShowcase.actions.buttonText1}
                </StyledButton>
            </StyledCard>
        </Container>
    );
};

const StyledCard = styled.div`
    position: relative;
    background-color: #f3f4f6;
    background-image: url(${showcaseBG});
    background-size: cover;
    border-radius: 30px;
    padding: 72px 32px 72px 468px;

    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    margin-bottom: 100px;

    @media (max-width: 920px) {
        background-image: url(${showcaseBgMobile});
        padding: 340px 28px 30px 28px;
        gap: 0;
    }
`;

const StyledImage = styled.img`
    position: absolute;
    top: calc(50% - 200px);
    left: 33px;
    width: 404px;
    height: 408px;

    @media (max-width: 920px) {
        top: 30px;
        left: calc(50% - 138px);
        width: 279px;
        height: 285px;
    }
`;

const StyledTitle = styled.div`
    display: block;
    width: 100%;
    color: #1f2937;

    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%; /* 48.96px */

    @media (max-width: 920px) {
        font-size: 18px;
        line-height: 136%; /* 24.48px */
    }
`;

const StyledDescription = styled.div`
    color: #1f2937;

    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%; /* 27.2px */
    margin: 19px 0 18px;

    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 136%; /* 19.04px */
        margin: 11px 0 0px;
    }
`;

const StyledButton = styled.a`
    padding: 0 24px;
    background: #2357ff;
    color: #ffffff !important;
    border: none;
    height: 60px;
    border-radius: 100px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-weight: 700 !important;
    }

    &:hover {
        background: #8ea6f8 !important;
    }

    &:active {
        background: #6588fa !important;
    }

    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 16px;
        padding: 0 16px;
        height: 48px;
        border-width: 4px;
        margin: 24px auto 0;
    }
`;
