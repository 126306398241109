import React from 'react';
import { Checkbox, Flex, Table, Tag, Typography } from 'antd';
import { StarOutlined } from '@ant-design/icons';

import { ProductListProps } from '../../interfaces/products.interface';
import { ProductActions } from './ProductActions';
import { statusList } from '../../utils/products';

const { Text } = Typography;

const renderStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'В наличии';
        case 2:
            return 'Нет в наличии';
        case 3:
            return 'В архиве';
        default:
            return '';
    }
};

export const ProductsTable = (props: ProductListProps) => {
    const {
        list,
        pagination,
        isLoading,
        updateFilters,
        filters,
        rowSelection,
        isMultipleActionsAvailable,
    } = props;

    const handleFilters = (name, value) => {
        updateFilters({ [name]: value });
    };

    const columns = [
        {
            title: <Text strong>Товар</Text>,
            dataIndex: 'title',
            key: 'title',
            ellipsis: true,
            width: '40%',
            render: (title, record) => (
                <Flex
                    gap={16}
                    align={'center'}
                    style={{
                        width: '100%',
                    }}
                >
                    <StarOutlined
                        style={{
                            fontSize: 24,
                        }}
                    />

                    <div style={{ width: '95%' }}>
                        <Text ellipsis={true}>{title}</Text>
                    </div>
                </Flex>
            ),
        },
        {
            title: <Text strong>Цена, ₽</Text>,
            dataIndex: 'price',
            key: 'price',
            ellipsis: true,
            width: '10%',
            render: (price, record) => (
                <Flex gap={4} align={'center'}>
                    {price}
                    <Text
                        delete
                        style={{
                            color: 'rgba(37, 33, 41, 0.45)',
                        }}
                    >
                        {record.old_price}
                    </Text>
                </Flex>
            ),
            sorter: (a, b) => a.price - b.price,
        },
        {
            title: <Text strong>Категория</Text>,
            dataIndex: 'categories',
            key: 'categories',
            ellipsis: true,
            width: '20%',
            render: (_, { categories }) => (
                <>
                    {categories.map(category => {
                        return <Tag key={category.id}>{category.name}</Tag>;
                    })}
                </>
            ),
        },
        {
            title: <Text strong>Статус</Text>,
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            width: '10%',
            filters: statusList,
            filterDropdown: ({ close }) => (
                <div style={{ padding: 8 }}>
                    <Flex vertical gap={8}>
                        {statusList.map((status, index) => (
                            <Flex
                                key={index}
                                onClick={() => {
                                    handleFilters('status', status.include);
                                    close();
                                }}
                            >
                                <Checkbox
                                    checked={
                                        !filters.status.includes(status.value)
                                    }
                                />
                                <Text style={{ cursor: 'pointer' }}>
                                    {status.label}
                                </Text>
                            </Flex>
                        ))}
                    </Flex>
                </div>
            ),
            render: (status, record) => (
                <Flex gap={4} align={'center'}>
                    {renderStatus(status)}
                </Flex>
            ),
        },
        {
            title: 'Действие',
            key: 'action',
            ellipsis: true,
            width: '15%',
            render: (_, record) => (
                <ProductActions
                    item={record}
                    isMultipleActionsAvailable={isMultipleActionsAvailable}
                />
            ),
        },
    ];

    return (
        <Table
            rowKey={(record: { id: number }) => record.id}
            loading={isLoading}
            columns={columns}
            pagination={pagination(list?.total)}
            dataSource={list?.items}
            rowSelection={rowSelection}
        />
    );
};
