import { DeleteOutlined, EditOutlined, InboxOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

export const statusList = [
    {
        label: 'В наличии',
        key: '1',
        text: 'В наличии',
        value: 1,
        include: [2],
    },
    {
        label: 'Нет в наличии',
        key: '2',
        text: 'Нет в наличии',
        value: 2,
        include: [1, 3],
    },
];

interface ProductActionsProps {
    position: number;
    type: ProductActionsEnum;
    component: ReactNode;
}

export enum ProductActionsEnum {
    archive = 'archive',
    delete = 'delete',
    edit = 'edit',
}

export const productActions: ProductActionsProps[] = [
    {
        position: 0,
        type: ProductActionsEnum.archive,
        component: <InboxOutlined />,
    },
    {
        position: 1,
        type: ProductActionsEnum.delete,
        component: <DeleteOutlined />,
    },
    {
        position: 2,
        type: ProductActionsEnum.edit,
        component: <EditOutlined />,
    },
];

export const productsActionDefault = {
    label: 'Массовое действие',
    key: 'default',
};

export const productsActions = [
    { key: 'changeCategory', label: 'Переместить в категорию' },
];
