import { Empty, Space, Table, Tag } from 'antd';
import React from 'react';
import { FilterOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
    ICommunity,
    ICommunityQueryParams,
    ICommunityTag,
} from 'features/communities/interfaces';
import { PaginatedResponse } from 'features/communities/interfaces/PaginatedResponse';
import { TableFilter } from 'shared/ui/TableFilter';

import { ChatTitleAvatar, chatType } from '../../ChatTitleAvatar';

type Props = {
    readonly source: PaginatedResponse<ICommunity>;
    readonly loading?: boolean;
    readonly filters: ICommunityQueryParams;
    readonly onFiltersUpdate: (filters: any) => void;
    readonly onSelect: (ids: number[]) => void;
};
export const ChatListTable: React.FC<Props> = props => {
    return (
        <StyledTable
            loading={props.loading}
            rowKey={community => community.id}
            locale={{ emptyText: <Empty description='Нет чатов' /> }}
            dataSource={props.source.items}
            pagination={{
                total: props.source.total,
                showSizeChanger: true,
                showTotal: () => `Чатов: ${props.source.total}`,
                onChange: (page, pageSize) => {
                    props.onFiltersUpdate({
                        offset: (page - 1) * pageSize,
                        limit: pageSize,
                    });
                },
                onShowSizeChange: (current, size) => {
                    props.onFiltersUpdate({
                        offset: (current - 1) * size,
                        limit: size,
                    });
                },
            }}
        >
            <Table.Column
                title={
                    <TableFilter<string>
                        title='Название сообщества'
                        onChange={name => {
                            props.onFiltersUpdate({ name });
                        }}
                        value={props.filters.name}
                    >
                        <TableFilter.Text />
                    </TableFilter>
                }
                render={chat => (
                    <ChatTitleAvatar
                        title={chat.title}
                        image={chat.image}
                        type={chat.type}
                        onClick={() => {
                            props.onFiltersUpdate({
                                chat: chat.id,
                                offset: 0,
                            });
                        }}
                    />
                )}
            />
            <Table.Column
                width={'20%'}
                sortDirections={['ascend', 'descend']}
                sorter={(a: any, b: any) => {
                    if (a.toLowerCase() < b.toLowerCase()) {
                        return -1;
                    }
                    if (a.toLowerCase() > b.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }}
                title={'Тип сообщества'}
                dataIndex={'type'}
                render={type => (
                    <React.Fragment>
                        <StyledSpace>
                            {type === chatType.channel && 'Канал'}
                            {type === chatType.group && 'Группа'}
                            {type === chatType.supergroup && 'Группа'}
                        </StyledSpace>
                    </React.Fragment>
                )}
            />

            <Table.Column
                width={'400px'}
                title={
                    <TableFilter<string[]>
                        icon={<FilterOutlined />}
                        onChange={tags_names => {
                            props.onFiltersUpdate({ tags_names });
                        }}
                        title='Теги'
                        value={props.filters.tags_names}
                    >
                        <TableFilter.Tags />
                    </TableFilter>
                }
                dataIndex={'tags'}
                render={(tags: ICommunityTag[]) => (
                    <React.Fragment>
                        <StyledSpace>
                            {tags.map((tag, index) => (
                                <Tag key={index}>{tag.name}</Tag>
                            ))}
                        </StyledSpace>
                    </React.Fragment>
                )}
            />
        </StyledTable>
    );
};

const StyledTable = styled(Table<ICommunity>)`
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-table-column-title {
        font-weight: bold;
    }
`;

const StyledSpace = styled(Space)`
    margin-bottom: 8px;
`;
