import React, { useMemo, useState } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import { Page } from 'shared/ui/Page';
import { BackButton } from 'shared/ui/BackButton';
import { useReadChatsQuery } from 'features/communities/services';

import { AnalyticsFilters } from '../components/AnalyticsFilters';
import { ChatTitleAvatar } from '../components/ChatTitleAvatar';
import { HeadStatistics } from '../components/Charts/HeadStatistics';
import { handleFiltersUpdate as hFiltersUpdate } from '../utils/handleFiltersUpdate';
import { IStatisticFilters } from '../services/interfaces';
import { AllAnalyticsLayout } from '../layouts/AllAnalyticsLayout';
import { MessagesLayout } from '../layouts/MessagesLayout';
import { MembersLayout } from '../layouts/MembersLayout';
import { ModerationLayout } from '../layouts/ModerationLayout';
import { FinanceLayout } from '../layouts/FinanceLayout';
import { downloadAnalytics } from '../utils/downloadAnalytics';
import { PostsLayout } from '../layouts/PostsLayout';

export enum chatType {
    group = 'group',
    supergroup = 'supergroup',
    channel = 'channel',
}

export const AnalyticsPage: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [filters, setFilters] = useState<IStatisticFilters>({
        tags_names: [],
        period: searchParams.get('period') || 'week',
        viewAnalitycsType: (searchParams.get('viewAnalitycsType') ||
            'all') as any,
        chat: searchParams.get('chat') || '',
        telegram_accounts: [],
    });

    const {
        data: chats = { items: [], total: 0 },
        isFetching,
        isLoading: isChatsListLoading,
    } = useReadChatsQuery({ tags_names: filters.tags_names });

    const handleFiltersUpdate = hFiltersUpdate.bind({
        filters,
        setFilters,
        setSearchParams,
    });

    const renderChat = (chat: number) => {
        const { ...found } = chats.items.find(c => chat == c.id);

        return {
            id: found.id,
            title: found.title,
            image: found.image,
        };
    };

    const onDownload = async (type: 'csv' | 'xlsx') => {
        await downloadAnalytics(filters, type);
    };

    const filterChatType = useMemo(() => {
        const currentChat = chats?.items?.find(c => filters.chat == c.id);
        return currentChat?.type || chatType.group;
    }, [filters, chats]);

    return (
        <Page title='Аналитика'>
            <StyledWrapper>
                <StyledHead>
                    {!filters.chat && (
                        <Typography.Text strong>Все сообщества</Typography.Text>
                    )}
                    {filters.chat && (
                        <BackButton
                            onClick={() => {
                                handleFiltersUpdate({
                                    chat: '',
                                    offset: 0,
                                });
                            }}
                        >
                            {isChatsListLoading ? (
                                <LoadingOutlined style={{ fontSize: '24px' }} />
                            ) : (
                                <ChatTitleAvatar
                                    {...renderChat(Number(filters.chat))}
                                />
                            )}
                        </BackButton>
                    )}
                    <AnalyticsFilters
                        onDownload={onDownload}
                        filters={filters}
                        onFiltersUpdate={handleFiltersUpdate}
                    />
                </StyledHead>
                <HeadStatistics filters={filters} />
                {filters.viewAnalitycsType === 'all' && (
                    <AllAnalyticsLayout
                        onFiltersUpdate={handleFiltersUpdate}
                        filters={filters}
                    />
                )}
                {filters.viewAnalitycsType === 'messages' &&
                    (filterChatType === chatType.channel ? (
                        <PostsLayout
                            onFiltersUpdate={handleFiltersUpdate}
                            filters={filters}
                        />
                    ) : (
                        <MessagesLayout
                            onFiltersUpdate={handleFiltersUpdate}
                            filters={filters}
                        />
                    ))}
                {filters.viewAnalitycsType === 'members' && (
                    <MembersLayout
                        onFiltersUpdate={handleFiltersUpdate}
                        filters={filters}
                    />
                )}

                {filters.viewAnalitycsType === 'moderation' && (
                    <ModerationLayout
                        onFiltersUpdate={handleFiltersUpdate}
                        filters={filters}
                    />
                )}
                {filters.viewAnalitycsType === 'finance' && (
                    <FinanceLayout
                        onFiltersUpdate={handleFiltersUpdate}
                        filters={filters}
                    />
                )}
            </StyledWrapper>
        </Page>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const StyledHead = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledCharts = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
`;
