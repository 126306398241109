import React, { useEffect, useState } from 'react';
import { App, Button, Flex, Typography } from 'antd';
import { useFormik } from 'formik';

import { ProductsActionsDropdown } from './ProductsActionsDropdown';
import { selectInitialFilters } from '../../utils/filters';
import { ProductCategoriesSelector } from '../Categories/ProductCategoriesSelector';
import { useUpdateProductsCategoriesMutation } from '../../services/products';
import { productsActionDefault, productsActions } from '../../utils/products';

const { Text } = Typography;

export const ProductsActions = props => {
    const { notification } = App.useApp();

    const {
        updateFilters,
        filters,
        rowSelection,
        isMultipleActionsAvailable,
        isFiltersEqual,
        setSelectedRowKeys,
    } = props;

    const [updateCategories, { isSuccess }] =
        useUpdateProductsCategoriesMutation();

    const [active, setActive] = useState(productsActionDefault);

    const handleClear = () => {
        updateFilters(selectInitialFilters('type', filters.shop_id));
    };

    const onSave = () => {
        if (!rowSelection || !formik) {
            return;
        }

        const payload = {
            product_ids:
                rowSelection.selectedRowKeys.length > 0
                    ? rowSelection.selectedRowKeys
                    : [],
            category_ids: formik.values.category_ids || [],
        };

        updateCategories(payload);
    };

    const initialValues = {
        category_ids: [],
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSave,
    });

    const renderAction = current => {
        switch (current.key) {
            case 'changeCategory':
                return (
                    <ProductCategoriesSelector
                        shopId={filters.shop_id}
                        formik={formik}
                        isDisabled={false}
                    />
                );
            case 'action':
                return <div>Другое действие</div>;
        }
    };

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Товары успешно перемещены',
            });
            setSelectedRowKeys([]);
        }
    }, [isSuccess]);

    return (
        <Flex justify={'space-between'} align={'center'}>
            {isMultipleActionsAvailable ? (
                <Flex gap={16} align={'center'}>
                    <Button onClick={onSave} type={'primary'}>
                        Применить
                    </Button>
                    <ProductsActionsDropdown
                        active={active}
                        setActive={setActive}
                        items={productsActions}
                    />

                    {renderAction(active)}
                    <Text>
                        Выбрано: {rowSelection?.selectedRowKeys?.length}
                    </Text>
                </Flex>
            ) : (
                <div />
            )}

            <Flex>
                {!isFiltersEqual && (
                    <Button onClick={handleClear} type={'link'}>
                        Очистить фильтры
                    </Button>
                )}
            </Flex>
        </Flex>
    );
};
