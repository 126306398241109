import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}


* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: #6257d2;
}

#root {
    height: 100%;

    .ant-app {
        height: 100%;
    }
}

.ant-select-tree-list-holder-inner {
    padding: 6px 6px 0 0;
}

.ant-select-tree-node-selected {
    padding-right: 21px !important;
    position: relative;
    display: block;

    &:after {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 20px;
        height: 20px;
        display: block;
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjgxMTggMy43MTA5NEgxNi40NDY2QzE2LjI1NTIgMy43MTA5NCAxNi4wNzM2IDMuNzk4ODMgMTUuOTU2NCAzLjk0OTIyTDcuOTAzNjQgMTQuMTUwNEw0LjA0MjMxIDkuMjU3ODFDMy45ODM5IDkuMTgzNjUgMy45MDk0NCA5LjEyMzY4IDMuODI0NTQgOS4wODI0MUMzLjczOTYzIDkuMDQxMTQgMy42NDY0OCA5LjAxOTY1IDMuNTUyMDcgOS4wMTk1M0gyLjE4Njg0QzIuMDU1OTggOS4wMTk1MyAxLjk4MzcxIDkuMTY5OTIgMi4wNjM3OSA5LjI3MTQ4TDcuNDEzNCAxNi4wNDg4QzcuNjYzNCAxNi4zNjUyIDguMTQzODcgMTYuMzY1MiA4LjM5NTgyIDE2LjA0ODhMMTcuOTM0OSAzLjk2MDk0QzE4LjAxNSAzLjg2MTMzIDE3Ljk0MjcgMy43MTA5NCAxNy44MTE4IDMuNzEwOTRaIiBmaWxsPSIjNjI1N0QyIi8+Cjwvc3ZnPg==");
    }
}
`;
