import React, { useState } from 'react';
import {
    App,
    Button,
    Form,
    Input,
    InputNumber,
    Select,
    Switch,
    Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { FeedFormProps, FeedTypes } from '../../interfaces/shop.interface';

const options: Array<{ value: FeedTypes; label: string }> = [
    {
        value: FeedTypes.yml,
        label: 'Яндекс',
    },
    {
        value: FeedTypes.fileYml,
        label: 'Яндекс (файл/yml)',
    },
    {
        value: FeedTypes.csvGoogleMerchant,
        label: 'Google Merchant',
    },
    {
        value: FeedTypes.wildberries,
        label: 'Wildberries',
    },
];

export const FeedForm = (props: FeedFormProps) => {
    const { form, initial } = props;
    const values = Form.useWatch([], form);
    const { notification } = App.useApp();

    const handleChange = info => {
        if (info.file.status === 'done') {
            notification.success({
                message: 'Файл фида успешно подготовлен к загрузке',
            });
        } else if (info.file.status === 'error') {
            notification.error({
                message: 'Ошибка загрузки файла',
            });
        }
        form.setFieldValue(`feed_file`, info.fileList);
    };

    return (
        <Form
            wrapperCol={{
                xs: 24,
                md: 16,
            }}
            form={form}
            name='feeds'
            initialValues={initial}
            layout={'horizontal'}
            autoComplete='off'
            requiredMark={false}
        >
            <Form.Item
                labelCol={{
                    span: 24,
                }}
                label='Тип фида'
                name='type'
                rules={[
                    {
                        required: true,
                        message: '',
                    },
                ]}
            >
                <Select
                    style={{
                        maxWidth: '283px',
                    }}
                    placeholder='Выберите формат фида'
                    options={options}
                />
            </Form.Item>

            {values?.type === FeedTypes.fileYml ? (
                <Form.Item
                    labelCol={{
                        span: 24,
                    }}
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}
                    label='Товарный фид'
                    name='feed_file'
                >
                    <Upload
                        onChange={handleChange}
                        beforeUpload={() => false} // Не отправляем файл сразу
                        // fileList={fileList}
                        fileList={values.feed_file}
                        maxCount={1}
                    >
                        <Button type='default'>
                            <UploadOutlined /> Загрузите фид
                        </Button>
                    </Upload>
                </Form.Item>
            ) : (
                <>
                    <Form.Item
                        labelCol={{
                            span: 24,
                        }}
                        rules={[
                            {
                                required: true,
                                message: '',
                            },
                        ]}
                        label='Ссылка на товарный фид'
                        name='feed_url'
                    >
                        <Input required placeholder={'Укажите ссылку на фид'} />
                    </Form.Item>

                    <Form.Item
                        labelCol={{
                            span: 24,
                        }}
                        rules={[
                            {
                                required: true,
                                message: '',
                            },
                        ]}
                        label='Частота обновления (часы)'
                        name='feed_frequency'
                    >
                        <InputNumber
                            required
                            maxLength={5}
                            max={99999}
                            min={1}
                        />
                    </Form.Item>

                    <Form.Item
                        label={'Активировать фид'}
                        valuePropName='checked'
                        style={{ marginBottom: 0 }}
                        name='is_active'
                    >
                        <Switch defaultChecked />
                    </Form.Item>
                </>
            )}
        </Form>
    );
};
