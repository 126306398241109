import {
    ProductsFilters,
    ProductsTypes,
} from '../interfaces/products.interface';

export const selectInitialFilters = (type, shopId) => {
    switch (type) {
        case 'product-order':
            return initialFiltersProductOrder(type, shopId);
        case 'links':
            return InitialFiltersLinks(type, shopId);
        case 'archive':
            return InitialFiltersArchived(type, shopId);
        case 'products':
            return initialFilters(type, shopId);
        default:
            return initialFilters(type, shopId);
    }
};

const commonInitial = {
    title: '',
    offset: 0,
    limit: 8,
    page: 1,
    category_id: null,
};

export const initialFilters = (type, shopId: number): ProductsFilters => {
    return {
        shop_id: shopId,
        status: [2],
        type: ProductsTypes.product,
        ...commonInitial,
    };
};

export const InitialFiltersArchived = (
    type,
    shopId: number
): ProductsFilters => {
    return {
        shop_id: shopId,
        status: [1, 3],
        type: ProductsTypes.product,
        ...commonInitial,
    };
};
export const InitialFiltersLinks = (type, shopId: number): ProductsFilters => {
    return {
        shop_id: shopId,
        status: [],
        type: ProductsTypes.link,
        ...commonInitial,
    };
};

export const initialFiltersProductOrder = (
    type,
    shopId: number
): ProductsFilters => {
    return {
        shop_id: shopId,
        status: [2],
        type: ProductsTypes.productOrder,
        ...commonInitial,
    };
};
