import { Outlet } from 'react-router-dom';
import { Navigate, RouteObject } from 'react-router';

import { AIKnowledgeContainer } from 'features/communities/components/Knowlegde/containers/AIKlownledgeCotainer';
import { AIKnowledgeEditContainer } from 'features/communities/components/Knowlegde/containers/AIKnowledgeEditContainer';
import { AiQuestionEditContainer } from 'features/communities/components/Questions/containers/AiQuestionEditContainer';

import { CrmPage } from './pages/CrmPage';
import OrdersContainer from '../shops/containers/Orders/OrdersContainer';
import { OrdersModal } from '../shops/containers/Orders/OrdersModal';
import { VisitorsContainer } from '../shops/containers/Visitors/VisitorsContainer';

const routes: RouteObject[] = [
    {
        path: 'crm',
        element: <CrmPage />,
        children: [
            {
                path: '',
                element: <Navigate to='visitors' />,
            },
            {
                path: 'visitors',
                element: <Outlet />,
                children: [
                    {
                        path: ':id',
                        element: <VisitorsContainer />,
                    },
                ],
            },
            {
                path: 'orders',
                element: <Outlet />,
                children: [
                    {
                        path: ':id',
                        element: <OrdersContainer />,
                        children: [
                            {
                                path: ':orderId',
                                element: <OrdersModal />,
                            },
                        ],
                    },
                ],
            },
            // {
            //     path: 'ai-knowledge',
            //     element: <Outlet />,
            //     children: [
            //         {
            //             path: '',
            //             element: <AIKnowledgeContainer />,
            //         },
            //         {
            //             path: 'ai-question/:id',
            //             element: <AIKnowledgeEditContainer />,
            //         },
            //         {
            //             path: 'question/:id',
            //             element: <AiQuestionEditContainer />,
            //         },
            //     ],
            // },
        ],
    },
];

export default routes;
