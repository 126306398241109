import React, { useEffect, useState, useMemo } from 'react';
import { TreeSelect } from 'antd';
import { useParams } from 'react-router-dom';

import { useLazyReadProductCategoryQuery } from '../../services/categories';
import { ProductsFilters } from '../../interfaces/products.interface';

type TreeCategoryFilterProps = {
    updateFilters: (filters: ProductsFilters) => void;
    width?: number;
    isDisabled?: boolean;
    filters: ProductsFilters;
};
export const TreeCategoryFilter: React.FC<TreeCategoryFilterProps> = props => {
    const { isDisabled = false, updateFilters, filters, width } = props;

    const { id } = useParams();

    const [active, setActive] = useState<number | string>('Все категории');

    const [getCategories, { data }] = useLazyReadProductCategoryQuery();

    useEffect(() => {
        if (id) {
            getCategories({ shop_id: +id });
        }
    }, [id]);

    const onChange = (categoryId: number | 'null') => {
        updateFilters({
            ...filters,
            category_id: categoryId === 'null' ? null : categoryId,
        });
        setActive(categoryId);
    };

    useEffect(() => {
        if (filters.category_id === null) {
            setActive('Все категории');
        }
    }, [filters.category_id]);

    const dropdownItems = useMemo(() => {
        return data?.items?.map(category => {
            if (category.id === null) {
                return {
                    ...category,
                    id: 'null',
                };
            }
            return category;
        });
    }, [data]);

    return (
        <TreeSelect
            style={{ width: width || 300 }}
            treeData={dropdownItems}
            fieldNames={{
                label: 'name',
                value: 'id',
                children: 'childCategoryList',
            }}
            onChange={onChange}
            defaultValue={'null'}
            value={active}
            disabled={isDisabled}
        />
    );
};
