import React, { FC, Fragment } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { FloatButton } from 'antd';

import LandingBg1 from 'static/landing/landing-bg-1.webp';
import LandingPhone1 from 'static/landing/landing-phone-1.webp';
import { Heading } from 'shared/layouts/LandingLayout/screen/Heading';
import Bg1 from 'static/landing/bg1.png';

import { RefsProps } from '../types/landing.interface';
import { TelegramAdvantages } from '../screen/TelegramAdvantages';
import { TelegramMiniApps } from '../screen/TelegramMiniApps';
import { PathToPurchase } from '../screen/PathToPurchase';
import { OurClients } from '../screen/OurClients';
import { IndividualConditions } from '../screen/IndividualConditions';
import { AboutUs } from '../screen/AboutUs';
import { EcomOpportunities } from '../screen/EcomOpportunities';
import { SlickStyle } from '../../../ui/App/slick';
import { Feedback } from '../../AppLayout/components/Navbar/components/Feedback';
import { SpodialShowcase } from '../screen/SpodialShowcase';

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${LandingBg1}), url(${LandingPhone1});
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: cover;
  }
`;

export const Content: FC<RefsProps> = ({
    headingRef,
    telegramRef,
    ecomRef,
    individualConditionsRef,
    aboutUsRef,
}) => {
    return (
        <Fragment>
            <GlobalStyle />
            <SlickStyle />
            <StyledContainer>
                <Heading refProp={headingRef} />
                <TelegramAdvantages />
                <TelegramMiniApps refProp={telegramRef} />
                <EcomOpportunities refProp={ecomRef} />
                <PathToPurchase />
                <OurClients />
                <IndividualConditions refProp={individualConditionsRef} />
                <AboutUs refProp={aboutUsRef} />
                <SpodialShowcase />
            </StyledContainer>
            <FloatButton.BackTop />
        </Fragment>
    );
};

const StyledContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 1600px;
    height: auto;
    padding: 0 20px;
    margin: 0 auto;
    z-index: 1;

    @media (max-width: 992px) {
        padding: 0 20px;
    }
`;
