import React from 'react';
import { Input } from 'antd';

import { GenericModal } from 'shared/ui/GenericModal';

import { ICategoryCreate } from '../../../interfaces/category.interface';

export const CategoryCreate: React.FC<ICategoryCreate> = (
    props: ICategoryCreate
) => {
    const { isOpen, setIsOpen, value, isEdit, isLoading, onConfirm } = props;

    return (
        <GenericModal
            title={isEdit ? 'Редактировать категорию' : 'Добавить категорию'}
            width={380}
            open={isOpen}
            okText={isEdit ? 'Редактировать' : 'Добавить'}
            okButtonProps={{
                disabled: value?.length === 0,
                loading: isLoading,
            }}
            onCancel={() => {
                setIsOpen(false);
            }}
            onConfirm={() => onConfirm(value)}
        >
            <Input
                type={'text'}
                placeholder={'Введите название категории'}
                onChange={e => props.setValue(e.target.value)}
                value={value}
            />
        </GenericModal>
    );
};
