import { createBrowserRouter } from 'react-router-dom';
import { Navigate, RouteObject } from 'react-router';

import { AppLayout } from 'shared/layouts';
import { ProtectedRoute } from 'shared/ui/ProtectedRoute';
import { NotFoundPage } from 'features/common/pages';
import CommunityFeature from 'features/communities';
import Crm from 'features/crm/routes';
import AuthFeature from 'features/auth';
import SubscriptionFeature from 'features/subscriptions';
import UserFeature from 'features/user';
import { LandingLayout } from 'shared/layouts/LandingLayout/LandingLayout';
import { AnalyticsPage } from 'features/analytics/pages/AnalyticsPage';
import { PublicLayout } from 'shared/layouts/PublicLayout';
import { AdminRoutes } from 'features/admin/routes';
import Courses from 'features/courses';

import { CategoryListContainer } from './features/communities/components/Categories/container/public/CategoryListContainer';
import { QuestionContainer } from './features/communities/components/Questions/containers/public/QuestionContainer';
import { QuestionTableContainer } from './features/communities/components/Questions/containers/public/QuestionTableContainer';
import { CategoryContainer } from './features/communities/components/Categories/container/public/CategoryContainer';
import { PrivacyPolicyPage } from './shared/layouts/PublicLayout/components/PrivacyPolicyPage';
import { UserAgreementPage } from './shared/layouts/PublicLayout/components/UserAgreementPage';
import { DocsPage } from './shared/layouts/PublicLayout/components/DocsPage';
import {
    MonetizationPublicRoutes,
    MonetizationRoutes,
} from './features/monetization/routes';
import { PayLayout } from './shared/layouts/PayLayout';
import Shops from './features/shops';
import UserAgreement from './shared/layouts/PublicLayout/pages/UserAgreement';
import PersonalDataPolicy from './shared/layouts/PublicLayout/pages/PersonalDataPolicy';
import PersonalDataAgreement from './shared/layouts/PublicLayout/pages/PersonalDataAgreement';
import PaymentsRules from './shared/layouts/PublicLayout/pages/PaymentsRules';
import Oferta from './shared/layouts/PublicLayout/pages/Oferta';
import UkassaContainer from './features/shops/containers/Payments/UkassaContainer';
import { Subscription } from './features/subscriptions/components/Subscription';
import { LegalHandler } from './features/subscriptions/components/Legal/LegalHandler';
import { PayPending } from './features/subscriptions/components/PayPending';

export const routes: RouteObject[] = [
    {
        path: '/pay',
        element: (
            <ProtectedRoute>
                <PayLayout />
            </ProtectedRoute>
        ),
        children: [
            { index: true, path: 'subscription', element: <Subscription /> },
            { path: 'legal', element: <LegalHandler /> },
            { path: 'pending', element: <PayPending /> },
        ],
    },
    {
        path: '/',
        element: <LandingLayout />,
    },

    {
        path: '/user_agreement',
        element: <PublicLayout />,
        children: [
            {
                path: '',
                element: <UserAgreement />,
            },
        ],
    },
    {
        path: '/privacy_policy',
        element: <PublicLayout />,
        children: [
            {
                path: '',
                element: <PersonalDataPolicy />,
            },
        ],
    },
    {
        path: '/personal-data-agreement',
        element: <PublicLayout />,
        children: [
            {
                path: '',
                element: <PersonalDataAgreement />,
            },
        ],
    },
    {
        path: '/payment_rules',
        element: <PublicLayout />,
        children: [
            {
                path: '',
                element: <PaymentsRules />,
            },
        ],
    },
    {
        path: '/oferta',
        element: <PublicLayout />,
        children: [
            {
                path: '',
                element: <Oferta />,
            },
        ],
    },
    {
        path: '/ukassa',
        element: <PublicLayout />,
        children: [
            {
                path: 'status',
                element: <UkassaContainer />,
            },
        ],
    },
    ...AuthFeature.routes,
    ...Courses.routes,

    {
        path: '/app/public',
        element: <PublicLayout />,
        children: [
            {
                path: 'knowledge-base',
                children: [
                    {
                        path: ':id',
                        element: <CategoryContainer />,
                        children: [
                            {
                                path: 'categories',
                                element: <CategoryListContainer />,
                            },
                            {
                                path: 'questions',
                                element: <QuestionTableContainer />,
                            },
                            {
                                path: 'question/:id',
                                element: <QuestionContainer />,
                            },
                        ],
                    },
                ],
            },
            ...MonetizationPublicRoutes,
        ],
    },

    {
        path: '/app',
        element: (
            <ProtectedRoute>
                <AppLayout />
            </ProtectedRoute>
        ),
        children: [
            {
                path: '',
                element: <Navigate to={'/pay/subscription'} />,
                // element: <Navigate to={CommunityFeature.routes[0].path} />,
            },
            ...UserFeature.routes,
            ...SubscriptionFeature.routes,
            ...CommunityFeature.routes,
            ...Shops.routes,
            ...AdminRoutes,
            ...Crm,

            ...MonetizationRoutes,

            {
                path: 'analitycs',
                element: <AnalyticsPage />,
            },
            {
                path: '*',
                element: <NotFoundPage />,
            },
        ],
    },
];

export const router = createBrowserRouter(routes);
