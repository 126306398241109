import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, Flex, Form, Typography } from 'antd';

import {
    useCreateFeedMutation,
    useCreateFileFeedMutation,
    useLazyReadFeedQuery,
} from '../../services/shops';
import { FeedForm } from '../../views/Feeds/FeedForm';
import { initial, messages } from '../../utils/feeds';
import { FeedNotifications } from '../../views/Feeds/FeedNotifications';
import { FeedTypes } from '../../interfaces/shop.interface';
import { createFormData } from '../../../../utils/common/createFormData';

export const FeedsSettingsContainer = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);

    const queryHook = (type: FeedTypes) => {
        switch (type) {
            case FeedTypes.fileYml:
                return useCreateFileFeedMutation();
            default:
                return useCreateFeedMutation();
        }
    };

    const [createFeed, { isSuccess, isError, error }] = queryHook(values?.type);
    const [getFeed, { data }] = useLazyReadFeedQuery();

    const [isSubmittable, setIsSubmittable] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            getFeed(+id);
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                ...data,
                feed_frequency: data.frequency,
                feed_url: data.url,
            });
        }
    }, [data]);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsSubmittable(true))
            .catch(() => setIsSubmittable(false));
    }, [form, values]);

    const handleSave = async () => {
        let payload;
        if (values?.type === FeedTypes.fileYml) {
            payload = {
                id: +id,
                body: createFormData({
                    type: 1,
                    feed_file: values.feed_file[0].originFileObj,
                }),
            };
        } else {
            payload = {
                id: +id,
                ...values,
            };
        }
        await createFeed(payload);
    };

    return (
        <Flex vertical gap={24}>
            <FeedNotifications
                isSuccess={isSuccess}
                isError={isError}
                error={error}
            />
            <FeedForm form={form} initial={initial} />
            <Divider style={{ margin: 0 }} />
            <Flex vertical={false} gap={24}>
                <Button
                    disabled={!isSubmittable}
                    type={'primary'}
                    onClick={handleSave}
                >
                    {messages.save}
                </Button>
                <Button onClick={() => navigate(-1)}>{messages.cancel}</Button>
            </Flex>
        </Flex>
    );
};
