import React, { FC, Fragment, useState } from 'react';
import { Button, Drawer, Flex, Menu } from 'antd';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

import Logo from 'static/images/logo.png';

import { RefsProps } from '../types/landing.interface';

export const Navbar: FC<RefsProps> = ({
    headingRef,
    telegramRef,
    ecomRef,
    individualConditionsRef,
    aboutUsRef,
}) => {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const menuItems = [
        {
            name: 'Telegram',
            goTo: () =>
                telegramRef.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth',
                }),
        },
        {
            name: 'Ecom',
            goTo: () =>
                ecomRef.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth',
                }),
        },
        {
            name: 'Условия',
            goTo: () =>
                individualConditionsRef.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth',
                }),
        },
        {
            name: 'О нас',
            goTo: () =>
                aboutUsRef.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth',
                }),
        },
    ];

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Drawer placement='left' onClose={onClose} open={open}>
                <StyledMobileMenu>
                    {menuItems.map((item, index) => (
                        <StyledMobileMenuItem
                            key={index}
                            onClick={() => {
                                item.goTo();
                                onClose();
                            }}
                        >
                            {item.name}
                        </StyledMobileMenuItem>
                    ))}
                </StyledMobileMenu>
            </Drawer>

            <StyledHeader>
                <HeaderContainer align={'center'}>
                    <StyledLogo>
                        <NavLink
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            to='/'
                        >
                            <svg
                                width='22'
                                height='24'
                                viewBox='0 0 22 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M0 12.0001C0 7.16311 0 4.74463 1.30078 3.11931C1.56442 2.78989 1.8624 2.48988 2.18959 2.22444C3.8039 0.914795 6.18673 0.914795 10.9524 0.914795C15.718 0.914795 18.1009 0.914795 19.7152 2.22444C20.0424 2.48988 20.3403 2.78989 20.604 3.11931C21.9048 4.74463 21.9048 7.16311 21.9048 12.0001C21.9048 16.837 21.9048 19.2555 20.604 20.8808C20.3403 21.2102 20.0424 21.5103 19.7152 21.7757C18.1009 23.0853 15.718 23.0853 10.9524 23.0853C6.18673 23.0853 3.8039 23.0853 2.18959 21.7757C1.8624 21.5103 1.56442 21.2102 1.30078 20.8808C0 19.2555 0 16.837 0 12.0001Z'
                                    fill='#6257D2'
                                />
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M13.5609 5.73534C12.7009 7.5984 11.5247 9.2951 10.0857 10.7487L6.75735 14.1107L4.88779 15.9991C4.72485 16.1628 4.92372 16.4322 5.1264 16.3195L5.84407 15.9009C5.87262 15.8843 5.90141 15.9222 5.8781 15.9457L3.23642 18.6141C3.06602 18.7834 3.27637 19.052 3.47524 18.9353L4.82696 18.1438C4.8555 18.127 4.88436 18.165 4.86102 18.1885L4.17358 18.8825C4.00816 19.0495 4.21538 19.3178 4.41461 19.2015L7.51075 17.3952C8.7109 16.6951 10.1685 16.7018 11.5544 16.7018C14.7401 16.7018 17.3226 14.0917 17.3226 10.8719C17.3226 10.0577 17.158 9.28276 16.8592 8.57855C16.8183 8.48199 16.8588 8.3689 16.9531 8.32464L18.6614 7.523L18.6616 7.51923C18.7464 7.47769 18.7546 7.35619 18.6694 7.30479L17.8619 6.83374C17.8011 6.7983 17.7657 6.73161 17.7652 6.66076C17.7578 5.44471 16.7775 4.44946 15.56 4.44946C14.6753 4.44946 13.9125 4.97565 13.5609 5.73534Z'
                                    fill='white'
                                />
                            </svg>

                            <StyledLogoName
                                xmlns='http://www.w3.org/2000/svg'
                                width='105'
                                height='23'
                                viewBox='0 0 105 23'
                                fill='none'
                            >
                                <path
                                    d='M0.554688 13.0832H5.18159C5.25468 13.5425 5.46666 13.9425 5.81751 14.2832C6.16837 14.624 6.63617 14.8906 7.22093 15.0832C7.82031 15.261 8.52202 15.3499 9.32606 15.3499C10.4371 15.3499 11.3142 15.2091 11.9575 14.9277C12.6007 14.6314 12.9223 14.2017 12.9223 13.6388C12.9223 13.2091 12.7396 12.8758 12.3741 12.6388C12.0086 12.4017 11.3069 12.2314 10.269 12.1277L7.199 11.8388C4.93307 11.6314 3.28843 11.1128 2.26511 10.2832C1.24178 9.43878 0.730115 8.30544 0.730115 6.88322C0.730115 5.72766 1.05904 4.74989 1.71689 3.94989C2.38937 3.14989 3.32498 2.54989 4.52373 2.14989C5.73711 1.73507 7.14784 1.52766 8.75592 1.52766C10.3348 1.52766 11.7382 1.75729 12.9662 2.21655C14.1942 2.661 15.1663 3.29803 15.8827 4.12766C16.6136 4.95729 17.0083 5.92026 17.0668 7.01655H12.4618C12.4034 6.61655 12.2133 6.27581 11.8917 5.99433C11.5847 5.69803 11.1607 5.47581 10.6198 5.32766C10.0789 5.1647 9.42839 5.08322 8.66821 5.08322C7.64488 5.08322 6.83353 5.22396 6.23415 5.50544C5.63477 5.77211 5.33509 6.17211 5.33509 6.70544C5.33509 7.09063 5.51051 7.40174 5.86137 7.63877C6.22684 7.87581 6.87739 8.03877 7.813 8.12766L11.0803 8.461C12.6446 8.60915 13.8945 8.8684 14.8301 9.23877C15.7657 9.59433 16.4455 10.0906 16.8694 10.7277C17.2934 11.3647 17.5054 12.1573 17.5054 13.1054C17.5054 14.2758 17.1618 15.298 16.4747 16.1721C15.7876 17.0314 14.8228 17.7054 13.5802 18.1943C12.3376 18.6684 10.8903 18.9054 9.23835 18.9054C7.54255 18.9054 6.04411 18.6684 4.74302 18.1943C3.45655 17.7054 2.44784 17.024 1.71689 16.1499C0.985947 15.261 0.598544 14.2388 0.554688 13.0832Z'
                                    fill='#6257D2'
                                />
                                <path
                                    d='M19.8269 5.861H24.3222V9.50544L24.1248 10.0388V13.5943L24.3222 14.6388V22.2166H19.8269V5.861ZM22.8968 12.2166C23.0869 10.8388 23.4816 9.65359 24.081 8.661C24.695 7.65359 25.4698 6.87581 26.4054 6.32766C27.341 5.7647 28.4009 5.48322 29.585 5.48322C30.813 5.48322 31.8875 5.7647 32.8085 6.32766C33.7441 6.89063 34.4678 7.67581 34.9794 8.68322C35.5057 9.69063 35.7688 10.8684 35.7688 12.2166C35.7688 13.5351 35.5057 14.7054 34.9794 15.7277C34.4678 16.7351 33.7441 17.5203 32.8085 18.0832C31.8875 18.6314 30.813 18.9054 29.585 18.9054C28.4009 18.9054 27.341 18.6314 26.4054 18.0832C25.4698 17.5203 24.7023 16.7351 24.1029 15.7277C23.5035 14.7203 23.1015 13.5499 22.8968 12.2166ZM31.2516 12.2166C31.2516 11.5795 31.12 11.0166 30.8569 10.5277C30.6083 10.0388 30.2502 9.65359 29.7824 9.37211C29.3292 9.09063 28.8102 8.94989 28.2255 8.94989C27.6261 8.94989 27.0559 9.09063 26.515 9.37211C25.9741 9.65359 25.499 10.0388 25.0897 10.5277C24.6804 11.0166 24.3661 11.5795 24.1468 12.2166C24.3661 12.8388 24.6804 13.3943 25.0897 13.8832C25.499 14.3721 25.9741 14.7573 26.515 15.0388C27.0559 15.3054 27.6261 15.4388 28.2255 15.4388C28.8102 15.4388 29.3292 15.3054 29.7824 15.0388C30.2502 14.7573 30.6083 14.3721 30.8569 13.8832C31.12 13.3943 31.2516 12.8388 31.2516 12.2166Z'
                                    fill='#6257D2'
                                />
                                <path
                                    d='M45.6022 18.9054C43.9941 18.9054 42.5907 18.6314 41.3919 18.0832C40.1932 17.5203 39.2576 16.7351 38.5851 15.7277C37.9126 14.7054 37.5764 13.5351 37.5764 12.2166C37.5764 10.8684 37.9126 9.69063 38.5851 8.68322C39.2576 7.67581 40.1932 6.89063 41.3919 6.32766C42.5907 5.7647 43.9941 5.48322 45.6022 5.48322C47.1957 5.48322 48.5918 5.7647 49.7905 6.32766C51.0039 6.89063 51.9468 7.67581 52.6193 8.68322C53.2918 9.69063 53.628 10.8684 53.628 12.2166C53.628 13.5351 53.2918 14.7054 52.6193 15.7277C51.9468 16.7351 51.0039 17.5203 49.7905 18.0832C48.5918 18.6314 47.1957 18.9054 45.6022 18.9054ZM45.6022 15.5499C46.3331 15.5499 46.9545 15.4166 47.4661 15.1499C47.9924 14.8832 48.3944 14.498 48.6722 13.9943C48.9499 13.4906 49.0888 12.898 49.0888 12.2166C49.0888 11.5054 48.9499 10.9054 48.6722 10.4166C48.3944 9.91285 47.9924 9.52766 47.4661 9.261C46.9545 8.99433 46.3331 8.861 45.6022 8.861C44.8713 8.861 44.2426 8.99433 43.7164 9.261C43.1901 9.52766 42.7881 9.91285 42.5103 10.4166C42.2325 10.9054 42.0937 11.498 42.0937 12.1943C42.0937 12.8906 42.2325 13.4906 42.5103 13.9943C42.7881 14.498 43.1901 14.8832 43.7164 15.1499C44.2426 15.4166 44.8713 15.5499 45.6022 15.5499Z'
                                    fill='#6257D2'
                                />
                                <path
                                    d='M67.355 18.5499L66.763 13.7943L67.5305 12.2388L66.7849 10.2832L67.8375 1.43878H72.4863L71.1925 11.861L71.9819 18.5499H67.355ZM68.3199 12.2166C68.1152 13.5499 67.7059 14.7203 67.0919 15.7277C66.4925 16.7351 65.725 17.5203 64.7894 18.0832C63.8684 18.6314 62.8159 18.9054 61.6317 18.9054C60.4037 18.9054 59.3219 18.6314 58.3863 18.0832C57.4653 17.5203 56.7417 16.7351 56.2154 15.7277C55.6891 14.7054 55.426 13.5351 55.426 12.2166C55.426 10.8684 55.6891 9.69063 56.2154 8.68322C56.7417 7.67581 57.4653 6.89063 58.3863 6.32766C59.3219 5.7647 60.4037 5.48322 61.6317 5.48322C62.8159 5.48322 63.8684 5.7647 64.7894 6.32766C65.725 6.87581 66.4998 7.65359 67.1138 8.661C67.7278 9.65359 68.1298 10.8388 68.3199 12.2166ZM59.9652 12.2166C59.9652 12.8388 60.0894 13.3943 60.3379 13.8832C60.6011 14.3721 60.9592 14.7573 61.4124 15.0388C61.8656 15.3054 62.3846 15.4388 62.9694 15.4388C63.5834 15.4388 64.1608 15.3054 64.7017 15.0388C65.2426 14.7573 65.7177 14.3721 66.127 13.8832C66.5364 13.3943 66.8434 12.8388 67.048 12.2166C66.8434 11.5795 66.5364 11.0166 66.127 10.5277C65.7177 10.0388 65.2426 9.65359 64.7017 9.37211C64.1608 9.09063 63.5834 8.94989 62.9694 8.94989C62.3846 8.94989 61.8656 9.09063 61.4124 9.37211C60.9592 9.65359 60.6011 10.0388 60.3379 10.5277C60.0894 11.0166 59.9652 11.5795 59.9652 12.2166Z'
                                    fill='#6257D2'
                                />
                                <path
                                    d='M74.5503 5.661L76.8089 6.10544L79.0456 5.661V18.5499H74.5503V5.661ZM76.787 4.61655C76.0122 4.61655 75.3836 4.42396 74.9012 4.03877C74.4187 3.63878 74.1775 3.09803 74.1775 2.41655C74.1775 1.74989 74.4187 1.21655 74.9012 0.816553C75.3836 0.416553 76.0122 0.216553 76.787 0.216553C77.591 0.216553 78.227 0.416553 78.6948 0.816553C79.1772 1.21655 79.4184 1.74989 79.4184 2.41655C79.4184 3.09803 79.1772 3.63878 78.6948 4.03877C78.227 4.42396 77.591 4.61655 76.787 4.61655Z'
                                    fill='#6257D2'
                                />
                                <path
                                    d='M93.2636 18.5499L92.6935 13.9721L93.2856 12.2166L92.6935 10.461L93.2636 5.861H97.9125L97.1231 12.1943L97.9125 18.5499H93.2636ZM94.3162 12.2166C94.1115 13.5499 93.7022 14.7203 93.0882 15.7277C92.4888 16.7351 91.7214 17.5203 90.7857 18.0832C89.8647 18.6314 88.8122 18.9054 87.628 18.9054C86.4001 18.9054 85.3183 18.6314 84.3826 18.0832C83.4616 17.5203 82.738 16.7351 82.2117 15.7277C81.6854 14.7054 81.4223 13.5351 81.4223 12.2166C81.4223 10.8684 81.6854 9.69063 82.2117 8.68322C82.738 7.67581 83.4616 6.89063 84.3826 6.32766C85.3183 5.7647 86.4001 5.48322 87.628 5.48322C88.8122 5.48322 89.8647 5.7647 90.7857 6.32766C91.7214 6.87581 92.4962 7.65359 93.1102 8.661C93.7242 9.65359 94.1262 10.8388 94.3162 12.2166ZM85.9615 12.2166C85.9615 12.8388 86.0857 13.3943 86.3343 13.8832C86.5974 14.3721 86.9556 14.7573 87.4088 15.0388C87.8619 15.3054 88.3809 15.4388 88.9657 15.4388C89.5797 15.4388 90.1571 15.3054 90.698 15.0388C91.2389 14.7573 91.7141 14.3721 92.1234 13.8832C92.5327 13.3943 92.8397 12.8388 93.0444 12.2166C92.8397 11.5795 92.5327 11.0166 92.1234 10.5277C91.7141 10.0388 91.2389 9.65359 90.698 9.37211C90.1571 9.09063 89.5797 8.94989 88.9657 8.94989C88.3809 8.94989 87.8619 9.09063 87.4088 9.37211C86.9556 9.65359 86.5974 10.0388 86.3343 10.5277C86.0857 11.0166 85.9615 11.5795 85.9615 12.2166Z'
                                    fill='#6257D2'
                                />
                                <path
                                    d='M100.504 1.43878H104.999V18.5499H100.504V1.43878Z'
                                    fill='#6257D2'
                                />
                            </StyledLogoName>
                        </NavLink>
                    </StyledLogo>
                    <StyledRightBlock>
                        <StyledMenu
                            theme='light'
                            mode='horizontal'
                            defaultSelectedKeys={['0']}
                            items={menuItems.map((item, index) => {
                                return {
                                    key: index,
                                    label: (
                                        <div onClick={item.goTo}>
                                            {item.name}
                                        </div>
                                    ),
                                };
                            })}
                        />
                        <ActionBlock>
                            <StyledButton
                                onClick={() => navigate('/pay/subscription')}
                                type='primary'
                            >
                                Начать
                            </StyledButton>
                        </ActionBlock>
                        <StyledMobileBurger onClick={() => showDrawer()}>
                            <MenuOutlined
                                style={{
                                    fontSize: '24px',
                                    color: 'rgb(31 41 55 / 42%)',
                                }}
                            />
                        </StyledMobileBurger>
                    </StyledRightBlock>
                </HeaderContainer>
            </StyledHeader>
        </Fragment>
    );
};

const StyledLogoName = styled.svg`
    margin-left: 3.65px;
    @media (max-width: 992px) {
        display: none;
    }
`;

const StyledMobileMenuItem = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(37, 33, 41, 0.88);
    cursor: pointer;
`;

const StyledMobileMenu = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;
`;

const StyledMobileBurger = styled.div`
    display: none;

    @media (max-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
`;

const HeaderContainer = styled(Flex)`
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;

    //@media (max-width: 992px) {
    //    display: flex;
    //    justify-content: space-between;
    //}
`;

const StyledRightBlock = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;

    @media (max-width: 992px) {
        gap: 16px;
    }
`;

const ActionBlock = styled.div`
    //padding-top: 21px;
`;

const StyledLogo = styled.div`
    width: 130px;
    //padding-top: 24px;

    @media (max-width: 992px) {
        //display: none;
    }
`;

const StyledHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 150;
    width: 100%;
    // height: 60px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border-bottom: 1px solid rgba(37, 33, 41, 0.06);
    padding: 0 50px;

    @media (max-width: 992px) {
        padding: 0 20px;
    }
`;

const StyledMenu = styled(Menu)`
    display: flex;
    border-bottom: 0;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 40px;
    gap: 5px;

    @media (max-width: 992px) {
        display: none;
    }
`;

const StyledButton = styled(Button)`
    padding: 7px 18px;
    color: #fff;
    background: #2357ff;
    border-radius: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    height: auto;

    &:hover {
        background: #8ea6f8 !important;
    }

    &:active {
        background: #6588fa !important;
    }
`;
