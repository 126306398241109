import React, { FC } from 'react';
import styled from 'styled-components';
import { Flex } from 'antd';

import StaticText from 'shared/layouts/LandingLayout/content.json';

import { SubTitle } from '../ui/Title';
import { Container } from '../ui/Container';
import { ScreenProps } from '../types/landing.interface';
import { Luding } from '../../../ui/Icons/landing/Luding';
import { Divan } from '../../../ui/Icons/landing/Divan';
import { Sitilink } from '../../../ui/Icons/landing/Sitilink';
import { Cofemania } from '../../../ui/Icons/landing/Cofemania';
import { Synergetic } from '../../../ui/Icons/landing/Synergetic';
import { TsvetnoeRu } from '../../../ui/Icons/landing/TsvetnoeRu';

export const OurClients: FC<ScreenProps> = () => {
    return (
        <Container maxWidth={1000} marginTop={98} mobileMarginTop={41}>
            <Flex vertical justify={'center'}>
                <SubTitle
                    id={'telegram-advantages'}
                    type='primary'
                    text={StaticText.clients.title}
                />

                <StyledClientsWrapper>
                    <StyledClientsList>
                        <Luding style={{ marginTop: '8px' }} />
                        <Divan />
                        <Sitilink />
                        <Cofemania />
                        <Synergetic />
                        <TsvetnoeRu />

                        <Luding style={{ marginTop: '8px' }} />
                        <Divan />
                        <Sitilink />
                        <Cofemania />
                        <Synergetic />
                        <TsvetnoeRu />
                    </StyledClientsList>
                </StyledClientsWrapper>
            </Flex>
        </Container>
    );
};

const StyledClientsWrapper = styled.div`
    margin: 0 -20px;
    overflow: hidden;

    @media (max-width: 920px) {
        margin: 0 -20px;
    }
`;

const StyledClientsList = styled.div`
    display: flex;
    height: 133px;
    gap: 16px 32px;
    align-items: center;
    margin-top: 34px;
    max-width: 100%;

    @keyframes ticker {
        0% {
            transform: translateX(0);
        }
        100% {
            //transform: translateX(-50%);
            transform: translateX(
                -1106px
            ); // Полная длинна c gap по бокам, считается вручную
        }
    }

    animation: ticker 10s linear infinite;
    -webkit-transform-style: preserve-3d;

    svg {
        flex: 1 0 auto;
    }

    @media (max-width: 920px) {
        height: 50px;
    }
`;
