import React, { Fragment } from 'react';

import { useGetPostsQuery } from 'features/analytics/services';
import { IStatisticFilters } from 'features/analytics/services/interfaces';

import { PostsTableView } from '../views/PostsTable';

type Props = {
    readonly filters: IStatisticFilters;
    readonly onFiltersUpdate: (update) => void;
};

export const PostsTableContainer: React.FC<Props> = props => {
    const {
        data: messages = {
            total_messages: 0,
            posts: [],
        },
        isFetching,
    } = useGetPostsQuery({
        community_id: props.filters.chat,
        period: props.filters.period,
        offset: props.filters.offset, // ToDO: возможно придет удалить как изменять api ручки
        limit: props.filters.limit, // ToDO: возможно придет удалить как изменять api ручки
    });

    return (
        <Fragment>
            <PostsTableView
                loading={isFetching}
                filters={props.filters}
                onFiltersUpdate={props.onFiltersUpdate}
                source={messages}
            />
        </Fragment>
    );
};
