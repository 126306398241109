import React from 'react';
import styled from 'styled-components';

import { MessageTopicsChart } from 'features/analytics/components/Charts/MessageTopicsChart';
import { MessagesChart } from 'features/analytics/components/Charts/MessagesChart';
import { ChatListContainer } from 'features/analytics/components/ChatList/containers/ChatListContainer';
import { IStatisticFilters } from 'features/analytics/services/interfaces';
import { MessagesMultiLineChart } from 'features/analytics/components/Charts/MessagesMultiLineChart';
import { ActiveMembersChart } from 'features/analytics/components/Charts/ActiveMembers';

import { PostsTableContainer } from './containers/PostsTableContainer';

type Props = {
    readonly filters: IStatisticFilters;
    readonly onFiltersUpdate: (update: Partial<IStatisticFilters>) => void;
};

export const PostsLayout: React.FC<Props> = props => {
    return (
        <StyledWrapper>
            <StyledCharts>
                <MessagesChart
                    height={140}
                    disableTonality={!!props.filters.chat}
                    filters={props.filters}
                />

                {props.filters.chat && (
                    <MessageTopicsChart
                        height={150}
                        community_id={props.filters.chat}
                        period={props.filters.period}
                    />
                )}
            </StyledCharts>
            <StyledTwoThirdLayout>
                <MessagesMultiLineChart
                    height={150}
                    filters={props.filters}
                    telegramAccounts={props.filters.telegram_accounts}
                />
                <StyledMultiLineContainer>
                    <ActiveMembersChart height={160} filters={props.filters} />
                </StyledMultiLineContainer>
            </StyledTwoThirdLayout>
            {!props.filters.chat && (
                <ChatListContainer
                    filters={props.filters}
                    onFiltersUpdate={props.onFiltersUpdate}
                />
            )}
            {props.filters.chat && (
                <PostsTableContainer
                    filters={props.filters}
                    onFiltersUpdate={props.onFiltersUpdate}
                />
            )}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const StyledCharts = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
`;

const StyledTwoThirdLayout = styled.div`
    display: flex;
    gap: 16px;
`;

const StyledMultiLineContainer = styled.div`
    flex: 2;
`;
