import React, { FC, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Flex, GlobalToken, Row, Space } from 'antd';

import Image54 from 'static/landing/image 54.png';
import StaticText from 'shared/layouts/LandingLayout/content.json';

import { Title } from '../ui/Title';
import { Container } from '../ui/Container';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenProps } from '../types/landing.interface';
import { GenericFlexModal } from '../../../ui/GenericFlexModal';
import { DemoEmail } from '../components/DemoEmail';

export const Heading: FC<ScreenProps> = ({ refProp }) => {
    const { size } = useWindowSize();
    const { hash } = useLocation();

    useLayoutEffect(() => {
        if (refProp && hash === '#create-store') {
            setTimeout(() => {
                refProp.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                });
            }, 500);
        }
    }, [refProp]);

    return (
        <Container maxWidth={1000} refProp={refProp}>
            <StyledContent>
                <Title level={1} text={StaticText.heading.title} />
                <StyledDescription>
                    {StaticText.heading.description}
                </StyledDescription>
                <DemoEmail
                    activator={
                        <StyledButton type='primary'>
                            {StaticText.heading.actions.buttonText1}
                        </StyledButton>
                    }
                />
            </StyledContent>
        </Container>
    );
};
const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 620px;

    @media (max-width: 920px) {
        margin: 0 auto;
        height: 304px;
        align-items: center;
    }
`;

const StyledDescription = styled.div`
    max-width: 520px;
    font-size: 20px;
    line-height: 27px;
    @media (max-width: 768px) {
        margin-top: 1rem;
        text-align: center;
    }
    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 19px;
        text-align: center;
    }
`;

const StyledButton = styled(Button)`
    height: 72px;
    padding: 0 24px;
    background: rgb(35, 87, 255);
    border: 8px solid rgb(255, 255, 255);
    border-radius: 100px;
    font-size: 16px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    margin-top: 33px;

    &:hover {
        background: #8ea6f8 !important;
    }

    &:active {
        background: #6588fa !important;
    }

    @media (max-width: 920px) {
        font-size: 14px;
        line-height: 16px;
        padding: 0 11px;
        height: 42px;
        border-width: 4px;
        margin: 24px auto 0;
    }
`;
