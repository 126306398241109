import React from 'react';

export const Cofemania = props => {
    const { color } = props;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='178'
            height='17'
            viewBox='0 0 178 17'
            fill='none'
        >
            <g clipPath='url(#clip0_460_1253)'>
                <path
                    d='M90.9897 6.70867C89.8292 8.27005 88.6718 9.83142 87.5113 11.3928C86.8686 12.2573 85.6411 12.272 84.9558 11.4222C83.6826 9.84024 82.4094 8.25828 80.9809 6.48226C80.9809 6.93803 80.9809 7.19091 80.9809 7.44378C80.9809 10.0196 80.9626 12.5954 80.9931 15.1712C80.9991 15.777 80.8286 16.021 80.1737 15.9769C79.4336 15.9269 78.6843 15.9946 77.9441 15.9416C77.7583 15.9299 77.4415 15.6594 77.4415 15.5065C77.4202 10.8018 77.4324 6.09706 77.4415 1.39237C77.4415 1.34826 77.4811 1.30415 77.5451 1.16301C78.4893 1.16301 79.4823 1.13361 80.4722 1.18654C80.6885 1.1983 80.923 1.4747 81.0844 1.67759C82.7262 3.73884 84.3527 5.8089 85.9853 7.87603C86.0584 7.97012 86.1467 8.05539 86.232 8.15243C87.8158 6.00591 89.3875 3.9035 90.9196 1.77462C91.2486 1.31886 91.5897 1.10421 92.1776 1.14243C92.9665 1.19536 93.7614 1.15419 94.6387 1.15419C94.6569 1.44236 94.6874 1.67759 94.6874 1.91577C94.6904 6.32348 94.6752 10.7341 94.7057 15.1418C94.7087 15.7946 94.526 16.0299 93.8437 15.974C93.2071 15.9211 92.5583 15.924 91.9217 15.974C91.2547 16.0269 91.1085 15.7564 91.1146 15.1624C91.142 12.3661 91.1267 9.56678 91.1267 6.77042C91.0811 6.75278 91.0354 6.7322 90.9897 6.70867Z'
                    fill='#2357FF'
                />
                <path
                    d='M157.565 15.9652C156.508 15.9652 155.515 15.9622 154.519 15.9681C153.977 15.971 154.078 15.5917 154.078 15.2859C154.072 12.6101 154.075 9.93727 154.075 7.26147C154.075 6.92332 154.075 6.58517 154.075 6.02061C153.764 6.3911 153.603 6.59399 153.432 6.78806C150.837 9.7285 148.248 12.6719 145.638 15.6005C145.476 15.7829 145.178 15.9387 144.934 15.9505C144.142 15.9946 143.344 15.9563 142.549 15.971C142.168 15.9769 141.979 15.8475 141.982 15.4594C141.995 10.7812 142.001 6.1 142.01 1.42177C142.01 1.37766 142.046 1.33061 142.116 1.16889C143.103 1.16889 144.142 1.14831 145.181 1.19535C145.315 1.20123 145.54 1.56291 145.543 1.76286C145.561 4.6092 145.552 7.45848 145.546 10.3048C145.546 10.4019 145.546 10.5018 145.546 10.6724C145.662 10.5989 145.75 10.5695 145.802 10.5077C148.412 7.54963 151.01 4.57979 153.645 1.63936C153.874 1.38354 154.318 1.21006 154.675 1.18359C155.616 1.12184 156.563 1.16301 157.568 1.16301C157.565 6.1147 157.565 11.0017 157.565 15.9652Z'
                    fill='#2357FF'
                />
                <path
                    d='M47.7387 11.0105C47.7387 12.469 47.7083 13.8745 47.7509 15.28C47.7692 15.8975 47.5743 16.1092 46.9316 16.074C46.0422 16.0269 45.1497 16.0622 44.1507 16.0622C44.1507 14.345 44.1507 12.7013 44.1507 11.0605C43.112 10.9341 42.1647 10.8841 41.251 10.69C39.3412 10.2872 37.8426 9.30507 37.1055 7.49376C36.076 4.96205 37.4619 2.31566 40.2276 1.50116C40.8824 1.30709 41.5799 1.16889 42.2622 1.15713C44.7233 1.11596 47.1844 1.12772 49.6455 1.15419C50.1754 1.16007 50.7176 1.26886 51.2354 1.3953C53.5229 1.96281 54.8631 3.41244 55.0215 5.44428C55.1859 7.54963 54.0072 9.41387 51.9147 10.2695C51.2842 10.5283 50.6019 10.6988 49.9287 10.8194C49.2495 10.937 48.552 10.9429 47.7387 11.0105ZM43.9862 8.54938C44.0684 8.32297 44.1141 8.2524 44.1141 8.18477C44.1233 7.2203 44.1263 6.25584 44.1293 5.29432C44.1324 3.62121 43.8339 3.42126 42.1312 3.9623C41.8906 4.03875 41.653 4.14167 41.4368 4.27105C40.6296 4.75916 40.389 5.50603 40.456 6.37934C40.523 7.24383 40.922 7.93483 41.7931 8.19065C42.5059 8.39942 43.2704 8.43764 43.9862 8.54938ZM47.7814 8.46117C49.0911 8.48469 50.3186 8.53468 51.0831 7.36733C51.607 6.56753 51.5278 5.37959 50.9278 4.72387C50.081 3.79764 48.9662 3.72707 47.7814 3.78882C47.7814 5.38253 47.7814 6.87627 47.7814 8.46117Z'
                    fill='#2357FF'
                />
                <path
                    d='M24.7139 16.2474C22.6914 16.2857 20.7664 15.9093 19.1094 14.7449C16.4747 12.8924 15.5975 10.3078 16.1427 7.28793C16.6788 4.30927 18.6008 2.39799 21.5218 1.44234C23.8032 0.695473 26.1211 0.768984 28.3964 1.50409C31.5123 2.50972 33.4099 4.97381 33.5531 8.11713C33.7054 11.4663 31.9997 14.1656 28.9081 15.48C27.6014 16.0328 26.4379 16.2474 24.7139 16.2474ZM29.7092 8.5729C29.6939 6.2676 28.5669 4.55038 26.6358 3.87997C22.996 2.61852 19.5694 5.07084 19.7613 8.79637C19.8892 11.2752 21.1258 12.9306 23.2671 13.4952C26.8338 14.4332 29.7366 12.2161 29.7092 8.5729Z'
                    fill='#2357FF'
                />
                <path
                    d='M123.645 7.0821C124.815 7.0821 125.887 7.0821 126.956 7.0821C128.162 7.0821 129.368 7.05858 130.571 7.09386C131.113 7.10856 131.284 6.93508 131.275 6.41756C131.248 4.98263 131.266 3.54476 131.266 2.10983C131.266 1.83931 131.266 1.56879 131.266 1.23064C132.451 1.23064 133.587 1.23064 134.784 1.23064C134.784 6.11176 134.784 10.9988 134.784 15.9269C133.623 15.9269 132.509 15.9269 131.284 15.9269C131.284 13.9069 131.284 11.9162 131.284 9.87257C128.707 9.87257 126.237 9.87257 123.645 9.87257C123.645 10.5195 123.645 11.1517 123.645 11.7839C123.645 12.9718 123.639 14.1597 123.648 15.3506C123.651 15.7123 123.605 15.9799 123.121 15.971C122.149 15.9534 121.175 15.9652 120.127 15.9652C120.127 11.034 120.127 6.14998 120.127 1.16595C121.169 1.16595 122.213 1.14242 123.252 1.19535C123.392 1.20417 123.621 1.60113 123.627 1.8246C123.66 3.286 123.645 4.7474 123.645 6.20585C123.645 6.47049 123.645 6.73513 123.645 7.0821Z'
                    fill='#2357FF'
                />
                <path
                    d='M173.39 10.4136C172.519 10.1549 171.901 10.3254 171.444 11.0047C170.451 12.4778 169.43 13.9333 168.456 15.4183C168.191 15.8211 167.904 15.9975 167.402 15.9799C166.333 15.9446 165.261 15.9681 163.908 15.9681C165.583 13.5922 167.131 11.4016 168.657 9.2404C168.2 8.90225 167.6 8.55234 167.116 8.08775C165.206 6.24998 165.605 3.24779 167.993 2.03927C168.897 1.58351 169.976 1.30122 170.993 1.23359C172.93 1.1101 174.883 1.19831 176.899 1.19831C176.899 6.09413 176.899 10.9753 176.899 15.9593C175.854 15.9593 174.809 15.9858 173.771 15.9299C173.631 15.9211 173.411 15.5094 173.405 15.2801C173.372 13.9186 173.387 12.5572 173.387 11.1958C173.39 10.9311 173.39 10.6665 173.39 10.4136ZM173.39 8.05246C173.39 7.07624 173.39 6.26762 173.39 5.459C173.39 3.63299 173.39 3.63299 171.492 3.86234C170.384 3.9976 169.625 4.64156 169.528 5.52663C169.412 6.59107 170.04 7.55259 171.145 7.82311C171.824 7.98483 172.546 7.97307 173.39 8.05246Z'
                    fill='#2357FF'
                />
                <path
                    d='M6.52113 8.29652C8.87865 10.8606 11.1814 13.3688 13.6424 16.0416C12.0677 16.0416 10.7214 16.0593 9.38123 16.021C9.18324 16.0152 8.97003 15.7535 8.80555 15.5741C7.25215 13.8598 5.70788 12.1338 4.15752 10.4166C3.99608 10.2372 3.81028 10.0784 3.49047 9.77261C3.49047 11.9515 3.49047 13.9627 3.49047 16.0534C2.35739 16.0534 1.33702 16.0622 0.319689 16.0504C-0.228573 16.0446 -0.0762778 15.6182 -0.0762778 15.333C-0.0884614 12.8571 -0.0823696 10.3783 -0.0823696 7.89956C-0.0823696 5.94123 -0.0823696 3.98584 -0.0823696 2.02751C-0.0823696 1.75993 -0.0823696 1.49235 -0.0823696 1.16008C1.12076 1.16008 2.25688 1.16008 3.49351 1.16008C3.49351 3.10959 3.49351 5.02381 3.49351 6.93804C3.55138 6.95862 3.60926 6.97626 3.66713 6.99684C4.66314 5.91476 5.65914 4.82974 6.6521 3.74766C6.85618 3.52419 7.10899 3.32718 7.25215 3.07136C8.56797 0.713134 10.7915 1.03952 13.1429 1.24241C10.9103 3.62122 8.75377 5.92064 6.52113 8.29652Z'
                    fill='#2357FF'
                />
                <path
                    d='M115.193 15.9622C113.935 15.9622 112.811 15.9828 111.69 15.9416C111.51 15.9357 111.276 15.6828 111.182 15.4917C110.776 14.6654 110.393 13.8274 110.042 12.9776C109.869 12.5542 109.637 12.366 109.138 12.3748C107.676 12.4072 106.214 12.3601 104.755 12.4101C104.484 12.419 104.103 12.6689 103.981 12.9071C103.561 13.7245 103.25 14.5949 102.857 15.427C102.757 15.6387 102.51 15.9269 102.318 15.9387C101.301 15.9886 100.28 15.9622 99.1289 15.9622C99.2568 15.6328 99.333 15.4064 99.4274 15.1918C101.407 10.7106 103.399 6.23228 105.364 1.74223C105.574 1.26294 105.851 1.15415 106.378 1.07476C107.932 0.842461 108.641 1.47171 109.232 2.84784C110.999 6.95857 112.951 10.9958 114.827 15.0653C114.937 15.3065 115.028 15.5623 115.193 15.9622ZM108.635 9.79315C108.075 8.32587 107.551 6.95857 106.957 5.40602C106.335 6.98503 105.796 8.35527 105.233 9.79315C106.409 9.79315 107.453 9.79315 108.635 9.79315Z'
                    fill='#2357FF'
                />
                <path
                    d='M60.6984 1.16006C64.1615 1.16006 67.6095 1.16006 71.1641 1.16006C70.8717 1.95986 70.6493 2.69203 70.3234 3.38303C70.2381 3.56534 69.8421 3.70354 69.5863 3.70942C68.1243 3.73883 66.6622 3.72412 65.2002 3.72412C64.92 3.72412 64.6428 3.72412 64.3017 3.72412C64.3017 4.85031 64.3017 5.92063 64.3017 7.0821C65.9464 7.0821 67.5547 7.0821 69.1629 7.0821C70.2503 7.0821 70.2503 7.0821 70.2503 8.10831C70.2503 8.52879 70.2472 8.94928 70.2411 9.3727C70.2411 9.44327 70.2137 9.51678 70.1772 9.67556C69.9274 9.69027 69.6594 9.72261 69.3883 9.72261C67.8989 9.72849 66.4125 9.72261 64.923 9.72849C64.5971 9.72849 64.2286 9.6638 64.2346 10.1784C64.2468 11.2134 64.2377 12.2484 64.2377 13.354C64.5758 13.3717 64.8469 13.4011 65.118 13.4011C66.8876 13.404 68.6603 13.4158 70.43 13.3952C70.9356 13.3893 71.1549 13.5099 71.161 14.0568C71.1884 16.0622 71.2097 16.0622 69.1446 16.0622C66.6287 16.0622 64.1159 16.0622 61.5999 16.0622C61.3228 16.0622 61.0425 16.0622 60.6953 16.0622C60.6984 11.0693 60.6984 6.13528 60.6984 1.16006Z'
                    fill='#2357FF'
                />
            </g>
            <defs>
                <clipPath id='clip0_460_1253'>
                    <rect
                        width='177'
                        height='16'
                        fill='white'
                        transform='translate(0.5 0.708008)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
