import React from 'react';
import { Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export const ProductsActionsDropdown = ({ items, active, setActive }) => {
    const menuAction = (item, currentItem) => {
        if (active.key !== currentItem.key) setActive(currentItem);
    };

    const menuProps = {
        items: items.map(item => ({
            key: item.key,
            label: item.label,
            onClick: e => menuAction(e, item),
        })),
    };

    return (
        <Dropdown menu={menuProps}>
            <Button>
                {active.label}
                <DownOutlined />
            </Button>
        </Dropdown>
    );
};
